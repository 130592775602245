import React from "react";
import { AdminSectionEditingIcon, Card, CardHeaderTitleCheckbox } from "./";

const AdminSectionItemCard: React.FC<{
  isSelected: boolean;
  onEdit: Function;
  sectionIsComplete?: boolean;
  renderExtra?: any;
  title: string;
  tooltipTitle?: string;
  hideExtra?: boolean;
}> = props => {
  return (
    <Card
      title={
        <CardHeaderTitleCheckbox
          sectionIsComplete={props.sectionIsComplete}
          title={props.title}
          tooltipTitle={props.tooltipTitle}
        />
      }
      extra={
        !props.hideExtra
          ? props.renderExtra || (
              <AdminSectionEditingIcon
                isSelected={props.isSelected}
                onEdit={props.onEdit}
              />
            )
          : null
      }
      style={props.isSelected ? styles.card : null}
    >
      {props.children}
    </Card>
  );
};
const styles = {
  card: {
    ".antCardHead": {
      backgroundColor: "#003c5f1a"
    }
  }
};
export default AdminSectionItemCard;
