import routes from "../routes/routes";

export const darkModePages = [
  routes.construction,
  routes.home,
  routes.search,
  routes.categoryLanding,
  routes.series,
  routes.resource,
  routes.pathway,
  routes.legacyLibraryAuthorWebPage,
  routes.legacyLibraryLandingWebpage,
  routes.legacyLibrarySeriesWebpage,
  routes.legacyLibrarySermonWebpage,
  routes.favorites,
  routes.featured,
  routes.recentlyViewed,
  routes.recentlyAdded
];
