import React, { Component, CSSProperties } from "react";
import debounce from "lodash/debounce";
import lowerCase from "lodash/lowerCase";
import { GlobalContext } from "../context/GlobalStateContext";
import SearchInput, { OptionObject } from "./home/SearchInput";
import NavigationSearchInput from "./search/NavigationSearchInput";
import routes from "../routes/routes";
import { algoliaSearch } from "../services/searchService";
import { filterResultsByLibrary, groupSearchResults } from "../utils/searchUtils";
import { navigate } from "../utils/routerHistory";

type Props = {
  allowClear?: boolean;
  darkMode?: boolean;
  showInNavigation?: boolean;
  filters?: string;
  placeholder?: string;
  searchIndex?: "published" | "system" | "editor";
  style?: CSSProperties;
  onSearchSelect?(value: OptionObject): void;
  onSearchSubmit?(value: OptionObject): void;
  onSearchClean?(): void;
};

type State = {
  autocompleteResults: AlogliaSearchGroups;
};

class Search extends Component<Props, State> {
  static contextType = GlobalContext;
  context!: React.ContextType<typeof GlobalContext>;

  state: State = {
    autocompleteResults: {}
  };

  onSearchSelect = (value: OptionObject) => {
    if (value.item_type_label) {
      const route = routes[lowerCase(value.item_type_label as string)].replace(":id", value.id.toString());
      navigate(route);
    }
  };

  onSearchSubmit = (value: OptionObject) => {
    navigate(`${routes.search}?query=${value.name}`);
  };

  autocompleteSearch = (value: string) => {
    algoliaSearch({
      query: value,
      filters: filterResultsByLibrary(this.props.filters),
      searchIndex: this.props.searchIndex,
      callback: (error, content) => {
        if (error || !content) {
          return;
        }
        const autocompleteResults = groupSearchResults(content);
        this.setState({ autocompleteResults });
      }
    });
  };

  changeAutocompleteQuery = (value: OptionObject) => {
    if (value.name.length > 2) {
      debounce(() => this.autocompleteSearch(value.name), 500)();
    }
  };

  render() {
    const { onSearchSelect = this.onSearchSelect, onSearchSubmit = this.onSearchSubmit } = this.props;

    if (this.props.showInNavigation) {
      return (
        <NavigationSearchInput
          darkMode={this.props.darkMode}
          dataSource={this.state.autocompleteResults}
          onSearchUpdate={this.changeAutocompleteQuery}
          onSearchSelect={onSearchSelect}
          onSearchSubmit={onSearchSubmit}
          isMobile={this.context.isMobile}
        />
      );
    }

    return (
      <SearchInput
        allowClear={this.props.allowClear}
        dataSource={this.state.autocompleteResults}
        onSearchUpdate={this.changeAutocompleteQuery}
        onSearchSelect={onSearchSelect}
        onSearchSubmit={onSearchSubmit}
        onSearchClean={this.props.onSearchClean}
        isMobile={this.context.isMobile}
        style={this.props.style}
        placeholder={this.props.placeholder}
      />
    );
  }
}

export default Search;
