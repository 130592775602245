import React, { Component } from "react";
import { css } from "emotion";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Modal, Tabs } from "antd";
import { Input } from "./";
import { GlobalContext } from "../context/GlobalStateContext";
import { AuthContext } from "../context/AuthContext";
import { postLogin, postSignUpAlt } from "../services/authService";
import {
  extractErrorMessage,
  showErrorNotification
} from "../utils/errorUtils";
import globalStyles from "../styles/globalStyles";
import globalColors from "../styles/globalColors";
import { sameAsValidation } from "../utils/validationUtils";
import { MEDIA_OPTIONS, CHURCH_ROLES } from "../utils/constants";
import Recaptcha from "react-recaptcha";
import routes from "../routes/routes";
import { navigate } from "../utils/routerHistory";
import { isInLegacyLibrary } from "../utils/generalUtils";

type State = {
  loadingLogin: boolean;
  loadingSignup: boolean;
  buttonEnabled: boolean;
};

type FormProps = {
  email: string;
  password: string;
};

type FormValues = {
    firstName: string;
    lastName: string;
    email: string;
    organizationName: string;
    postalCode: string;
    password: string;
    passwordConfirmation: string;
    marketingMediaId: string;
    churchRoleId: string;
  };

const TabPane = Tabs.TabPane;

class LoginSignupModal extends Component<{}, State> {
  form: any;

  recaptchaInstance;

  state = {
    loadingLogin: false,
    loadingSignup: false,
    buttonEnabled: false
  };

  onSignUpAttempt = async (
    values: FormValues,
    setCurrentUserAndAuth: (user: any) => Promise<void>,
    toggleSignUpModalAlt: Function,
    signUpSuccessCallbackAlt: Function | null,
  ) => {
    if (!this.state.buttonEnabled) {
      return;
    }

    try {

      this.setState({ loadingSignup: true });

      // @ts-ignore
      const response = await postSignUpAlt(values);

     setCurrentUserAndAuth(response.data);

      this.setState({ loadingSignup: false });

      this.form.resetForm();

      // NAVIGATE SSO
      if (isInLegacyLibrary()) {
        navigate(`${process.env.REACT_APP_DOMAIN}${routes.ssoCallback}?ops_id=${response.data.token.identifier}`);
      } else {
        navigate(`${process.env.REACT_APP_LEGACY_LIBRARY_DOMAIN}${routes.ssoCallback}?ops_id=${response.data.token.identifier}`);
      }
    } catch (e) {
      showErrorNotification(extractErrorMessage(e));
      this.setState({ loadingSignup: false });
    }
  };

  onLoginAttempt = async (
    values: FormProps,
    setCurrentUserAndAuth: Function,
    toggleLoginModal: Function,
    loginSuccessCallback: Function | null
  ) => {
    try {
      this.setState({ loadingLogin: true });
      const response = await postLogin(values.email, values.password);

      setCurrentUserAndAuth(response.data)
        .then(() => {
          this.setState({ loadingLogin: false });

          this.form.resetForm();

          //if (loginSuccessCallback) loginSuccessCallback();

          // NAVIGATE SSO
          if (isInLegacyLibrary()) {
            navigate(`${process.env.REACT_APP_DOMAIN}${routes.ssoCallback}?ops_id=${response.data.token.identifier}`);
          } else {
            navigate(`${process.env.REACT_APP_LEGACY_LIBRARY_DOMAIN}${routes.ssoCallback}?ops_id=${response.data.token.identifier}`);
          }
        })
    } catch (e) {
      showErrorNotification(extractErrorMessage(e));

      this.setState({ loadingLogin: false });
    }
  };

  onForgotPassword = (toggleForgotPasswordModal, toggleLoginModal) => {
    toggleLoginModal();
    toggleForgotPasswordModal(this.form.state.values.email);
    this.form.resetForm();
  };

  render() {
    return (
      <GlobalContext.Consumer>
        {({
          loginSignupModalVisible,
          loginSuccessCallback,
          toggleForgotPasswordModal,
          toggleLoginModal,
          signUpSuccessCallbackAlt,
          teamInviteSlug,
          toggleSignUpModalAlt,
          toggleLoginSignupModal
        }) => (
          <AuthContext.Consumer>
            {({ setCurrentUserAndAuth }) => (
              <Modal
                visible={loginSignupModalVisible}
                onCancel={() => toggleLoginSignupModal()}
                footer={null}
                bodyStyle={{ ...styles.bodyStyle }}
                closable={false}
              >
                  <h2 style={{textAlign:"center", fontWeight: "bold", marginTop: 12}}>Log In or Sign Up <br></br>to access this content</h2>
                  <Tabs defaultActiveKey="1" size="large" tabBarStyle={{paddingLeft: '31%'}}>
                    <TabPane tab="Log In" key="1">
                    <Formik
                  initialValues={{
                    email: "",
                    password: ""
                  }}
                  ref={form => (this.form = form)}
                  onSubmit={values =>
                    this.onLoginAttempt(
                      values,
                      setCurrentUserAndAuth,
                      toggleLoginModal,
                      loginSuccessCallback
                    )
                  }
                  validationSchema={yup.object().shape({
                    email: yup
                      .string()
                      .email()
                      .matches(/^\S*$/, "No spaces allowed")
                      .required("Email is required")
                    // TEMP FIX TO REMOVE ERROR
                    // password: yup.string().required("Password is required")
                  })}
                  render={formProps => (
                    <div>
                      <form onSubmit={formProps.handleSubmit}>
                        <Input
                          {...formProps}
                          icon="user"
                          name="email"
                          placeholder="Email"
                          autoCapitalize="none"
                        />
                        <Input
                          {...formProps}
                          icon="lock"
                          name="password"
                          type="password"
                          placeholder="Password"
                          containerStyle={{
                            ".ant-form-item": { marginBottom: 10 }
                          }}
                        />
                        <button
                          className={css([
                            globalStyles.button,
                            styles.forgotPasswordLink
                          ])}
                          type="button"
                          onClick={() => {
                            this.onForgotPassword(
                              toggleForgotPasswordModal,
                              toggleLoginModal
                            );
                          }}
                        >
                          Forgot Password?
                        </button>
                        <Button
                          type="primary"
                          size="large"
                          loading={this.state.loadingLogin}
                          htmlType="submit"
                          className={css(styles.button)}
                        >
                          Log in
                        </Button>
                      </form>
                    </div>
                  )}
                />
                    </TabPane>
                    <TabPane tab="Sign Up" key="2">
                    <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    organizationName: "",
                    postalCode: "",
                    password: "",
                    passwordConfirmation: "",
                    marketingMediaId: "",
                    churchRoleId: ""
                  }}
                  ref={form => (this.form = form)}
                  onSubmit={values =>
                    this.onSignUpAttempt(
                      values,
                      setCurrentUserAndAuth,
                      toggleSignUpModalAlt,
                      signUpSuccessCallbackAlt
                    )
                  }
                  validationSchema={
                    teamInviteSlug
                      ? invitationValidationSchema
                      : validationSchema
                  }
                  render={formProps => (
                    <div>
                      <form onSubmit={formProps.handleSubmit}>
                        <div style={{display: "flex"}} {...css({ display: "flex"})}>
                          <Input
                            {...formProps}
                            containerStyle={{ flex: 1, marginRight: 10 }}
                            label="First Name"
                            name="firstName"
                          />
                          <Input
                            {...formProps}
                            containerStyle={{ flex: 1 }}
                            label="Last Name"
                            name="lastName"
                          />
                        </div>
                        <div style={{display: "flex"}} {...css({ display: "flex" })}>
                          <Input
                            {...formProps}
                            label="Email"
                            name="email"
                            containerStyle={
                              teamInviteSlug
                                ? { flex: 2, marginRight: 10 }
                                : { flex: 1 }
                            }
                          />
                          {teamInviteSlug && (
                            <Input
                              {...formProps}
                              containerStyle={{ flex: 1 }}
                              label="Postal Code"
                              name="postalCode"
                            />
                          )}
                        </div>
                        {!teamInviteSlug && (
                          <div>
                            <div style={{display: "flex"}} {...css({ display: "flex" })}>
                              <Input
                                {...formProps}
                                label="Organization Name"
                                name="organizationName"
                                containerStyle={{ flex: 2, marginRight: 10 }}
                              />
                              <Input
                                {...formProps}
                                containerStyle={{ flex: 1 }}
                                label="Postal Code"
                                name="postalCode"
                              />
                            </div>
                            <div style={{display: "flex"}} {...css({ display: "flex" })}>
                              <Input
                                {...formProps}
                                containerStyle={{ flex: 1 }}
                                label="How Did You Hear About Us"
                                name="marketingMediaId"
                                select
                                selectOptions={MEDIA_OPTIONS}
                              />
                            </div>
                            <div style={{display: "flex"}} {...css({ display: "flex" })}>
                              <Input
                                {...formProps}
                                containerStyle={{ flex: 1 }}
                                label="Church Role"
                                name="churchRoleId"
                                select
                                selectOptions={CHURCH_ROLES}
                              />
                            </div>
                          </div>
                        )}
                        <div style={{display: "flex"}} {...css({ display: "flex" })}>
                          <Input
                            {...formProps}
                            containerStyle={{ flex: 1, marginRight: 10 }}
                            label="Password"
                            name="password"
                            type="password"
                          />
                          <Input
                            {...formProps}
                            containerStyle={{ flex: 1 }}
                            label="Password Confirmation"
                            name="passwordConfirmation"
                            type="password"
                          />
                        </div>
                        <div style={{marginBottom: "2rem"}}>
                          <Recaptcha
                            ref={ e => this.recaptchaInstance = e }
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_NEW}
                            render="explicit"
                            size="invisible"
                            verifyCallback={(response) => {
                              this.setState({ buttonEnabled: true });

                              // Submit the form.
                              this.form.handleSubmit();
                            }}
                          />
                        </div>
                        <Button
                          type="primary"
                          size="large"
                          loading={this.state.loadingSignup}
                          htmlType="submit"
                          style={{...styles.button}}
                          onClick={(e) => {
                            e.preventDefault();

                            // Call the recaptcha instance.
                            this.recaptchaInstance.execute();
                          }}
                        >
                          Sign Up
                        </Button>
                      </form>
                    </div>
                  )}
                />
                    </TabPane>
                  </Tabs>
              </Modal>
            )}
          </AuthContext.Consumer>
        )}
      </GlobalContext.Consumer>
    );
  }
}

yup.addMethod(yup.mixed, "sameAs", sameAsValidation);

const validationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email()
    .matches(/^\S*$/, "No spaces allowed")
    .required("Email is required"),
  marketingMediaId: yup.string().required("Please select how you heard about us"),
  churchRoleId: yup.string().required("Please select your church role"),
  organizationName: yup.string().required("Organization Name is required"),
  postalCode: yup.string().required("Postal Code is required"),
  password: yup.string().required("Password is required"),
  passwordConfirmation: yup
    .string()
    // @ts-ignore
    .sameAs(yup.ref("password"), "Passwords must match")
    .required("Confirmation is required")
});

const invitationValidationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email()
    .matches(/^\S*$/, "No spaces allowed")
    .required("Email is required"),
  postalCode: yup.string().required("Postal Code is required"),
  password: yup.string().required("Password is required"),
  passwordConfirmation: yup
    .string()
    // @ts-ignore
    .sameAs(yup.ref("password"), "Passwords must match")
    .required("Confirmation is required")
});






const styles = {
  adminLoginButton: {
    marginTop: 20
  },
  adminLoginButtonContainer: {
    textAlign: "center" as "center"
  },
  bodyStyle: {
    paddingTop: 10
  },
  button: {
    width: "100%"
  },
  forgotPasswordLink: {
    marginBottom: 25,
    color: globalColors.blue
  },
  greetingText: {
    fontSize: 17,
    fontWeight: "bold" as "bold",
    color: globalColors.red,
    textAlign: "center" as "center",
    margin: 2
  },
  notificationText: {
    fontSize: 14,
  },
};

export default LoginSignupModal;
