import React from "react";
import { Card, Progress, Spin, Table } from "antd";
import { css } from "../";
import globalStyles from "../../styles/globalStyles";
import { numberWithCommas } from "../../utils/generalUtils";
import { Link } from "@reach/router";

type Props = {
  cardStyle: any;
  error?: boolean;
  loading?: boolean;
  interactedList?: boolean;
  interactedListPercentColor?: string;
  listData: Array<DashboardListData>;
  subtitleNumber?: string;
  subtitleText?: string;
  title: string;
};

const tableData = (props: Props) => {
  if (!props || !props.listData) return null;

  return props.listData.map((item, index) => {
    return {
      key: index,
      column1: item.name,
      column2: numberWithCommas(item.count),
      column3: item.percent,
      item: item
    }
  })
}

const columns = (props: Props) => {
  return [
    {
      ...css(globalStyles.dashboard.tableColumn),
      title: '',
      dataIndex: 'column1',
      key: 'column1',
      render: (value, record, index) => {
        if (record.item.route) {
          return <Link to={record.item.route}>{value}</Link>
        }

        return value;
      }
    },
    {
      ...css([globalStyles.dashboard.tableColumn, { textAlign: "right", paddingRight: "10px !important" }]),
      title: '',
      dataIndex: 'column2',
      key: 'column2',
    },
    {
      ...css(globalStyles.dashboard.tableColumn),
      title: '',
      dataIndex: 'column3',
      key: 'column3',
      width: 50,
      render: (value, record, index) => 
        <Progress percent={value} showInfo={false} status="normal" />
    },
  ];
}

export default (props: Props) => {
  return (
    <Spin spinning={props.loading || false}>
      <Card
        bordered={false}
        {...css([
          globalStyles.dashboard.card,
          globalStyles.dashboard.cardMargin,
          props.cardStyle
        ])}
      >
        <span {...css(globalStyles.dashboard.cardTitle)}>{props.title}</span>
        {props.subtitleNumber && props.subtitleText ? (
          <>
            <span
              {...css([globalStyles.dashboard.largeNumber, { marginRight: 5 }])}
            >
              {props.subtitleNumber}
            </span>
            <span {...css(globalStyles.dashboard.largeNumberSubText)}>
              {props.subtitleText}
            </span>
          </>
        ) : null}
        {props.interactedList ? (
          <div {...css(styles.interactedListHeader)}>
            <span {...css(styles.interactedListSpacer)} />
            <span {...css(styles.interactedListHeaderText)}>VIEWED</span>
            <span {...css(styles.interactedListHeaderText)}>INT'D (%)</span>
          </div>
        ) : null}
        {props.error ? (
          <div {...css(globalStyles.dashboard.error)}>Error loading data</div>
        ) : (
          <Table
            {...css(globalStyles.dashboard.table)}
            style={{border: 0}}
            columns={columns(props)}
            dataSource={tableData(props)}
            showHeader={false}
            pagination={false}
            bordered={false}
            size="small"
          />
        )}
      </Card>
    </Spin>
  );
};

const styles = {
  interactedListHeader: {
    display: "flex"
  },
  interactedListHeaderText: {
    flex: 1,
    textAlign: "right",
    fontWeight: "600",
    fontSize: 12,
    color: "#909090"
  },
  interactedListSpacer: {
    flex: 2
  }
};
