import React, { Component } from "react";
import { css } from "emotion";
import { Formik, FormikActions, FormikProps } from "formik";
import * as yup from "yup";
import { Button, Modal } from "antd";
import { Input } from "./";
import { GlobalContext } from "../context/GlobalStateContext";
import { AuthContext } from "../context/AuthContext";
import { postLogin } from "../services/authService";
import { extractErrorMessage, showErrorNotification } from "../utils/errorUtils";
import { isInLegacyLibrary } from "../utils/generalUtils";
import globalStyles from "../styles/globalStyles";
import globalColors from "../styles/globalColors";

type State = {
  loading: boolean;
};

type FormProps = {
  email: string;
  password: string;
};

class LoginModal extends Component<any, State> {
  state: State = {
    loading: false
  };

  onLoginAttempt = async (
    values: FormProps,
    actions: FormikActions<FormProps>,
    setCurrentUserAndAuth: (data: any) => Promise<void>,
    toggleLoginModal: () => void,
    loginSuccessCallback: () => void | null
  ) => {
    try {
      this.setState({ loading: true });
      const response = await postLogin(values.email, values.password);
      await setCurrentUserAndAuth(response.data);
      this.setState({ loading: false });

      actions.resetForm();

      //if (typeof loginSuccessCallback === "function" && loginSuccessCallback) loginSuccessCallback();

      toggleLoginModal();

      // NAVIGATE SSO
      window.location.reload();
    } catch (e) {
      showErrorNotification(extractErrorMessage(e));
      this.setState({ loading: false });
    }
  };

  onForgotPassword = (formProps: FormikProps<FormProps>, toggleForgotPasswordModal, toggleLoginModal) => {
    toggleLoginModal();
    toggleForgotPasswordModal(formProps.values.email);
    formProps.resetForm();
  };

  render() {
    return (
      <GlobalContext.Consumer>
        {({
          loginModalVisible,
          loginSuccessCallback,
          toggleForgotPasswordModal,
          toggleLoginModal,
          toggleSignUpModal
        }) => (
          <AuthContext.Consumer>
            {({ setCurrentUserAndAuth }) => (
              <Modal
                visible={loginModalVisible}
                onCancel={() => {
                  toggleLoginModal();
                }}
                title="Login"
                footer={null}
                bodyStyle={{ ...styles.bodyStyle }}
              >
                <Formik
                  initialValues={{
                    email: "",
                    password: ""
                  }}
                  onSubmit={(values, actions) =>
                    this.onLoginAttempt(values, actions, setCurrentUserAndAuth, toggleLoginModal, loginSuccessCallback)
                  }
                  validationSchema={yup.object().shape({
                    email: yup.string().email().matches(/^\S*$/, "No spaces allowed").required("Email is required")
                    // TEMP FIX TO REMOVE ERROR
                    // password: yup.string().required("Password is required")
                  })}
                  render={(formProps) => (
                    <div>
                      <form onSubmit={formProps.handleSubmit}>
                        <Input {...formProps} icon="user" name="email" placeholder="Email" autoCapitalize="none" />
                        <Input
                          {...formProps}
                          icon="lock"
                          name="password"
                          type="password"
                          placeholder="Password"
                          containerStyle={{
                            ".ant-form-item": { marginBottom: 10 }
                          }}
                        />
                        <button
                          className={css([globalStyles.button, styles.forgotPasswordLink])}
                          type="button"
                          onClick={() => {
                            this.onForgotPassword(formProps, toggleForgotPasswordModal, toggleLoginModal);
                          }}
                        >
                          Forgot Password?
                        </button>
                        <Button
                          type="primary"
                          size="large"
                          loading={this.state.loading}
                          htmlType="submit"
                          className={css(styles.button)}
                        >
                          Log in
                        </Button>
                        <Button
                          type="link"
                          size="large"
                          onClick={() => {
                            toggleLoginModal();

                            if (isInLegacyLibrary()) {
                              window.location.assign(`${process.env.REACT_APP_LEGACY_LIBRARY_DOMAIN}/legacy-library?signup=true`);
                            } else {
                              window.location.assign(`${process.env.REACT_APP_DOMAIN}?signup=true`);
                            }
                          }}
                          className={css(styles.button)}
                        >
                          Sign Up
                        </Button>
                      </form>
                    </div>
                  )}
                />
              </Modal>
            )}
          </AuthContext.Consumer>
        )}
      </GlobalContext.Consumer>
    );
  }
}

const styles = {
  adminLoginButton: {
    marginTop: 20
  },
  adminLoginButtonContainer: {
    textAlign: "center"
  },
  bodyStyle: {
    paddingTop: 10
  },
  button: {
    width: "100%"
  },
  forgotPasswordLink: {
    marginBottom: 25,
    color: globalColors.blue
  },
  greetingText: {
    fontSize: 17,
    fontWeight: "bold",
    color: globalColors.red,
    textAlign: "center",
    margin: 2
  },
  notificationText: {
    fontSize: 14
  }
};

export default LoginModal;
