import React from "react";
import { Icon, Tooltip } from "antd";
import colors from "../styles/globalColors";

export default (props: {
  sectionIsComplete?: boolean;
  title: string;
  tooltipTitle?: string;
}) => {
  const { sectionIsComplete } = props;
  const iconType = sectionIsComplete ? "check-circle" : "minus-circle";
  const theme = sectionIsComplete ? "twoTone" : "outlined";
  const color = sectionIsComplete ? colors.green : colors.gray;

  return (
    <span>
      {sectionIsComplete !== undefined ? (
        <Icon
          type={iconType}
          style={{ color: color, fontSize: 20, marginRight: 16 }}
          twoToneColor={color}
          theme={theme}
        />
      ) : null}
      <Tooltip title={props.tooltipTitle || null}>{props.title}</Tooltip>
    </span>
  );
};
