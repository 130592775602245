import { notification } from "antd";
import isObject from "lodash/isObject";
import isEmpty from "lodash/isEmpty";
import join from "lodash/join";
import map from "lodash/map";
import split from "lodash/split";
import capitalize from "lodash/capitalize";
import defaultImgWhite from "../assets/defaultImageWhite.jpg";
import defaultImgBlack from "../assets/defaultImageBlack.jpg";

export const isInLegacyLibrary = () => {
  return location.hostname.indexOf("legacylibraries") > -1 || 
    location.hostname.indexOf("gatewaylegacylibrary") > -1 ||
    (location.pathname.indexOf("/legacy-library") > -1 && location.pathname.indexOf("/admin/legacy-library") === -1);
};

export const sort = (a: any, b: any, field: string) => {
  if (a[field] < b[field]) {
    return -1;
  }
  if (a[field] > b[field]) {
    return 1;
  }
  return 0;
};

export const showSuccessNotification = (params: { message: string; description?: string }) => {
  notification.success({
    message: params.message,
    description: params.description,
    top: 92
  });
};

export const isEmptyObject = (value: any) => isObject(value) && isEmpty(value);

export const testImage = (url: string, timeout?: number) =>
  new Promise((res) => {
    timeout = timeout || 5000;
    let timedOut = false;
    let timer: any;
    const img = new Image();

    img.onerror = img.onabort = function () {
      if (!timedOut) {
        clearTimeout(timer);
        res("error");
      }
    };

    img.onload = function () {
      if (!timedOut) {
        clearTimeout(timer);
        res("success");
      }
    };

    img.src = url;

    timer = setTimeout(function () {
      timedOut = true;
      // reset .src to invalid URL so it stops previous
      // loading, but doesn't trigger new load
      img.src = "//!!!!/test.jpg";
      res("timeout");
    }, timeout);
  });

export const formatAddress = (a: {
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  state?: string;
  postalCode?: string;
}) => {
  const lines = [];
  if (a.address1) lines.push(a.address1);
  if (a.address2) lines.push(a.address2);
  if (a.address3) lines.push(a.address3);
  if (a.city || a.state || a.postalCode) {
    let line = [];
    if (a.city) line.push(a.city);
    if (a.state) line.push(a.state);
    if (line.length) {
      line = [line.join(", ")];
    }
    if (a.postalCode) line.push(a.postalCode);
    lines.push(line.join(" "));
  }
  return lines;
};

export const numberWithCommas = (value: string | number) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const camelCaseToSpaces = (text: string) => {
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export const roundToOneDecimal = (value: number, total: number) => Math.round((value / total) * 100 * 10) / 10;

export const isJSONString = (value: string) => {
  if (
    /^[\],:{}\s]*$/.test(
      value
        .replace(/\\["\\\/bfnrtu]/g, "@")
        .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, "]")
        .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
    )
  ) {
    return true;
  }
  return false;
};

export const pluralize = (count: number, value: string) => (count > 1 ? `${value}s` : value);

export const defaultImage = (darkMode: boolean) => (darkMode ? defaultImgWhite : defaultImgBlack);

export const humanize = (value: string) => {
  return value
    .replace(/^[\s_]+|[\s_]+$/g, "")
    .replace(/[_\s]+/g, " ")
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase();
    });
};

// insert a space before all caps
// uppercase the first character
export const camelCaseToTitle = (value: string) => {
  return value.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
    return str.toUpperCase();
  });
};

// convert underscore enum to title. e.g. "senior_pastor" => "Senior Pastor
export const underscoreToTitle = (value: string) => {
  return join(map(split(value, "_"), capitalize), " ");
};
