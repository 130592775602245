import API from "./baseService";

export const getFavorites = (page: number, perPage: number) => API.get(`/favorites?page=${page}&per_page=${perPage}`);

export const getLegacyFavorites = (page: number, perPage: number) =>
  API.get(`/favorites/legacy?page=${page}&per_page=${perPage}`);

export const favoriteResource = (resourceId: number, libraryType = 0) =>
  API.post("/favorites/resources", {
    favorite: {
      resource_id: resourceId,
      library_type: libraryType
    }
  }).then((response) => response.data);

export const favoriteSeries = (seriesId: number, libraryType = 0) =>
  API.post("/favorites/series", {
    favorite: {
      series_id: seriesId,
      library_type: libraryType
    }
  }).then((response) => response.data);

export const favoritePathway = (pathwayId: number) =>
  API.post("/favorites/pathways", {
    favorite: {
      pathway_id: pathwayId
    }
  }).then((response) => response.data);

export const removeFavorite = (itemId: number, type: "resources" | "series" | "pathways" | null) =>
  API.delete(`/favorites/${type}/${itemId}`).then((response) => response.data);

export default {
  getFavorites,
  getLegacyFavorites,
  favoriteResource,
  favoriteSeries,
  favoritePathway,
  removeFavorite
};
