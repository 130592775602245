export const USER_ROLES = {
  USER: "user",
  EDITOR: "editor",
  ADMIN: "admin"
};

export const USER_TIERS = {
  STANDARD: "standard",
  PREMIUM: "premium",
  NETWORK: "network"
};

const { EDITOR, ADMIN } = USER_ROLES;

export const PERMISSIONS = {
  // Authors
  canCreateAuthors: [EDITOR, ADMIN],
  canEditPublishedAuthors: [ADMIN],

  // Authors
  canCreateCopyrights: [EDITOR, ADMIN],
  canEditCopyrights: [ADMIN],

  // Featured Banners
  canDeleteFeaturedBanners: [ADMIN],
  canEditFeaturedBanners: [ADMIN],

  // Pathways
  canCreatePathway: [EDITOR, ADMIN],
  canEditPublishedPathway: [ADMIN],
  canEditUnpublishedPathway: [EDITOR, ADMIN],
  canDeletePathway: [ADMIN],
  canPublishPathway: [ADMIN],

  // Resources
  canCreateResource: [EDITOR, ADMIN],
  canEditPublishedResource: [ADMIN],
  canEditUnpublishedResource: [EDITOR, ADMIN],
  canDeleteResource: [ADMIN],
  canPublishResource: [ADMIN],

  // Series
  canCreateSeries: [EDITOR, ADMIN],
  canEditPublishedSeries: [ADMIN],
  canEditUnpublishedSeries: [EDITOR, ADMIN],
  canDeleteSeries: [ADMIN],
  canPublishSeries: [ADMIN],

  // Coupons
  canEditCoupons: [ADMIN],

  // Teams
  canEditTeamMemberLimit: [ADMIN],

  // Users
  canEditUser: [ADMIN],
  canPromoteUserToAdmin: [ADMIN],
  canPromoteUserToEditor: [ADMIN],
  canInviteNewUser: [ADMIN]
};
