import React from "react";
import { Redirect } from "@reach/router";
import { AuthContext } from "../context/AuthContext";
import { showErrorNotification } from "../utils/errorUtils";

export default ({
  component: Component,
  admin,
  ...rest
}: {
  component: React.LazyExoticComponent<typeof React.Component>;
  admin?: boolean;
}) => (
  <AuthContext.Consumer>
    {({ isBackendUser, isPublicAuthUser }) => {
      const userIsAuthorized = admin
        ? isBackendUser()
        : isBackendUser() || isPublicAuthUser();

      if (!userIsAuthorized) {
        // showErrorNotification("You are not allowed to view this page.");
      }

      return userIsAuthorized ? (
        // @ts-ignore
        <Component {...rest} />
      ) : (
        <Redirect to="/" noThrow />
      );
    }}
  </AuthContext.Consumer>
);
