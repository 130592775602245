import React from "react";
import { Col, Icon, Layout, Row } from "antd";

import { css } from "./";
import logoGray from "../assets/logoGray.png";
import colors from "../styles/globalColors";

const { Footer: AntdFooter } = Layout;

type Props = {
  darkMode: boolean;
  isMobile: boolean;
};

const Footer = ({ darkMode, isMobile }: Props) => {
  const isInAdminSection = location.pathname.startsWith("/admin");
  return (
    <Row style={{ backgroundColor: isInAdminSection ? colors.adminBackground : colors.footer }} type="flex">
      <Col
        span={24}
        style={{
          maxWidth: 1400,
          margin: "auto",
          width: isMobile ? "100%" : "75%"
        }}
      >
        <AntdFooter
          {...css({ ...styles.container, backgroundColor: isInAdminSection ? colors.adminBackground : colors.footer })}
        >
          <Row type="flex">
            <Col xs={24} sm={24} md={7}>
              <a href={process.env.REACT_APP_DOMAIN}>
                <img src={darkMode ? logoGray : logoGray} alt="Gateway Resource Library Logo" {...css(styles.logo)} />
              </a>
              <div {...css(styles.copyrightRow)}>
                &copy; {new Date().getFullYear()} Gateway Church. All rights reserved
                <br />
                Read our <a href="https://gatewaypeople.com/privacy">Privacy Policy</a>.
              </div>
            </Col>
            <Col xs={18} sm={18} md={4} {...css(styles.linksContainer)}>
            </Col>
            <Col xs={18} sm={18} md={4} {...css(styles.linksContainer)}>
            </Col>
            <Col xs={12} sm={12} md={4} {...css(styles.linksContainer)}>
              <ul {...css({ listStyleType: "none" })}>
                <li>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/GatewayResourceLibrary">
                    Facebook
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/gatewayresourcelibrary">
                    Instagram
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </AntdFooter>
      </Col>
    </Row>
  );
};

const styles = {
  container: {
    marginTop: "1.5rem",
    paddingRight: 0,
    paddingLeft: 0,
    paddingTop: 75,
    marginBottom: 100,
    "@media all and (max-width: 420px)": {
      "&": {
        borderBottom: "0 solid",
      }
    }
  },
  copyrightRow: {
    fontSize: "14px",
    marginBottom: 20,
    marginLeft: 20,
    color: "#808080",
    a: {
      color: "#808080"
    }
  },
  linksContainer: {
    li: {
      paddingBottom: 8,
      a: {
        fontFamily: "Montserrat, Helvetica, Arial, Lucida, sans-serif",
        fontSize: 12,
        fontWeight: 400,
        color: colors.lightGray,
        letterSpacing: 1,
        i: {
          marginRight: 5
        }
      }
    }
  },
  logo: {
    height: 40,
    width: 167,
    marginBottom: 20,
    marginLeft: 20
  }
};

export default Footer;
