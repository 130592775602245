import React from "react";
import { Tag } from "antd";
import { css } from "emotion";

export default ({ hide, isPublished }) => {
  if (hide) return null;

  const text = isPublished ? "Published" : "Draft";
  const color = isPublished ? "green" : "red";

  return (
    <div>
      <Tag closable={false} color={color} className={css(styles.draftTag)}>
        {text}
      </Tag>
    </div>
  );
};

const styles = {
  draftTag: {
    marginLeft: 8,
    marginTop: 2,
    borderRadius: 5,
    cursor: "default"
  }
};
