import React, { Component, Fragment } from "react";
import { AuthContext } from "../context/AuthContext";
import { GlobalContext } from "../context/GlobalStateContext";
import routes from "../routes/routes";
import { navigate } from "../utils/routerHistory";
import globalColors from "../styles/globalColors";
import { css } from ".";
import { isInLegacyLibrary } from "../utils/generalUtils";

import AccountIcon from "../assets/icons/account-icon.svg";
import AdminIcon from "../assets/icons/admin-icon.svg";
import ContactIcon from "../assets/icons/contact-us-icon.svg";
import LegacyIcon from "../assets/icons/legacy-library-icon.svg";
import ResourceIcon from "../assets/icons/resource-icon.svg";

import SigninIcon from "../assets/icons/sign-in-icon.svg";
import SignoutIcon from "../assets/icons/sign-out-icon.svg";
import SignupIcon from "../assets/icons/sign-up-icon.svg";

import globalStyles from "../styles/globalStyles";

type Props = {
  toggleLoginModal: () => void;
  toggleSettingsModal: () => void;
  toggleSignUpModal: () => void;
  toggleMobileMenu: () => void;
  darkMode?: boolean;
  isLegacyLibraryNav?: boolean;
};

class MobileMenuNav extends Component<Props> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;


  handleClick = (key: string) => {
    this.props.toggleSettingsModal();
    this.props.toggleMobileMenu();

    switch (key) {
      case "signout":
        navigate(routes.home).then(() => this.context.logout(() => window.location.reload()));
        break;

      case "signin":
        this.props.toggleLoginModal();
        break;

      case "signUp":
        if (isInLegacyLibrary()) {
          window.location.assign(`${process.env.REACT_APP_LEGACY_LIBRARY_DOMAIN}/legacy-library?signup=true`);
        } else {
          window.location.assign(`${process.env.REACT_APP_DOMAIN}?signup=true`);
        }

        break;

      case "profile":
        if (isInLegacyLibrary()) {
          window.location.assign(process.env.REACT_APP_LEGACY_LIBRARY_DOMAIN + routes.profile);
        } else {
          window.location.assign(process.env.REACT_APP_DOMAIN + routes.profile);
        }
        break;

      case "admin":
        window.location.assign(process.env.REACT_APP_DOMAIN + routes.admin.dashboard);
        break;

      case "legacylibrary":
        window.location.assign(process.env.REACT_APP_LEGACY_LIBRARY_DOMAIN + routes.legacyLibraryLandingWebpage);
        break;

      case "resourcelibrary":
        window.location.assign(process.env.REACT_APP_DOMAIN);
        break;

      case "contact":
        window.location.assign( `https://gatewayresourcelibrary.com/contact` );
        break;

    }
  };

  menuIcon = (key: string) => {

    switch (key) {
      case "signout":
        return SignoutIcon
        break;

      case "signin":
        return SigninIcon
        break;

      case "signUp":
        return SignupIcon
        break;


      case "profile":
        return AccountIcon
        break;

      case "admin":
        return AdminIcon
        break;

      case "legacylibrary":
        return LegacyIcon
        break;

      case "resourcelibrary":
        return ResourceIcon
        break;

      case "contact":
        return ContactIcon
        break;

    }
  };

  renderMenuItem(key: string, text: string | Element, mobileMenuVisible?: boolean ) {
    return (
      <div
        key={key}
        className="menu-item admin-menu-item"
        {...css({
          width: this.context.isAdmin() ? "20%" : (this.context.isAuthenticated() ? "25%" : "33%")
        })}
      >
        <a
          onClick={() => this.handleClick(key)}
          {...css({
            color: this.props.darkMode ? globalColors.white : globalColors.black,
            "&:hover": {
              color: this.props.darkMode ? globalColors.white : globalColors.black,
            }
          })}
        >

          <img
            className="mobile_menu_icon"
            src={ this.menuIcon(key) }
            alt={key}
          />
          <div className="mobile_menu_text">{text}</div>
        </a>
      </div>
    );
  }

  render() {
    const { isLegacyLibraryNav } = this.props;
    const loggedIn = this.context.isAuthenticated();
    const isAdmin = this.context.isAdmin();

    return (
      <GlobalContext.Consumer>
        {({ mobileMenuVisible }) => (
          <Fragment>
            {!isLegacyLibraryNav
              ? this.renderMenuItem("legacylibrary", "Legacy")
              : this.renderMenuItem("resourcelibrary", "Resource") }

            { loggedIn ? this.renderMenuItem("profile", "Account") : null }

            { this.renderMenuItem("contact", "Contact Us") }

            { loggedIn && isAdmin ? this.renderMenuItem("admin", "Admin") : null }

            { loggedIn
                ? this.renderMenuItem("signout", "Logout")
                : this.renderMenuItem("signin", "Login") }

          </Fragment>
        )}
      </GlobalContext.Consumer>
    );
  }
}

export default MobileMenuNav;
