import React, { Component, Fragment } from "react";
import { AuthContext } from "../context/AuthContext";
import { GlobalContext } from "../context/GlobalStateContext";
import routes from "../routes/routes";
import { navigate } from "../utils/routerHistory";
import { Progress, Tooltip } from "antd";
import globalColors from "../styles/globalColors";
import { css } from ".";
import { isInLegacyLibrary } from "../utils/generalUtils";
import facebookIcon from "../assets/facebook.png";
import instagramIcon from "../assets/instagram.png";

import globalStyles from "../styles/globalStyles";

type Props = {
  toggleLoginModal: () => void;
  toggleSettingsModal: () => void;
  toggleSignUpModal: () => void;
  toggleMobileMenu: () => void;
  darkMode?: boolean;
  isLegacyLibraryNav?: boolean;
};

class MenuNav extends Component<Props> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  handleClick = (key: string) => {
    this.props.toggleSettingsModal();
    this.props.toggleMobileMenu();
    switch (key) {
      case "logout":
        navigate(routes.home).then(() => this.context.logout(() => window.location.reload()));
        break;

      case "login":
        this.props.toggleLoginModal();
        break;

      case "profile":
        if (isInLegacyLibrary()) {
          window.location.assign(process.env.REACT_APP_LEGACY_LIBRARY_DOMAIN + routes.profile);
        } else {
          window.location.assign(process.env.REACT_APP_DOMAIN + routes.profile);
        }
        break;

      case "admin":
        window.location.assign(process.env.REACT_APP_DOMAIN + routes.admin.dashboard);
        break;

      case "legacylibrary":
        window.location.assign(process.env.REACT_APP_LEGACY_LIBRARY_DOMAIN + routes.legacyLibraryLandingWebpage);
        break;

      case "resourcelibrary":
        window.location.assign(process.env.REACT_APP_DOMAIN);
        break;

    }
  };

  renderMenuItem(key: string, text: string | Element, mobileMenuVisible?: boolean) {
    if (!key || (text === "Progress" && mobileMenuVisible)) return null;

    if (text === "Progress") {
      const percent = this.context.profileCompletePercentage();
      return (
        <div {...css(styles.progressContainer)}>
          <button {...css(globalStyles.button)} onClick={() => this.handleClick("profile")}>
            <Tooltip title={this.context.profileIsComplete() ? "Profile Complete!" : `Profile ${percent}% Complete`}>
              <Progress
                type="circle"
                strokeColor={globalColors.darkestGray}
                strokeWidth={12}
                percent={percent}
                width={25}
                showInfo={this.context.profileIsComplete()}
              />
            </Tooltip>
          </button>
        </div>
      );
    }

    return (
      <li
        key={key}
        className="menu-item admin-menu-item"
        {...css({
          padding: mobileMenuVisible ? "10px !important" : 0,
          textAlign: mobileMenuVisible ? "center" : "left"
        })}
      >
        <a
          onClick={() => this.handleClick(key)}
          {...css({
            color: this.props.darkMode ? globalColors.white : globalColors.black,
            "&:hover": {
              color: this.props.darkMode ? globalColors.white : globalColors.black,
              borderBottom: `2px solid ${this.props.darkMode ? globalColors.white : globalColors.black}`
            }
          })}
        >
          {text}
        </a>
      </li>
    );
  }

  render() {
    return (
      <GlobalContext.Consumer>
        {({ mobileMenuVisible }) => (
          <Fragment>
            <li
              key="facebook"
              className="menu-item admin-menu-item"
              {...css({
                padding: mobileMenuVisible ? "10px !important" : 0,
                textAlign: mobileMenuVisible ? "center" : "left"
              })}
            >
              <a
                onClick={() => {
                  navigate("https://www.facebook.com/GatewayResourceLibrary");
                }}
              >
                <img
                  {...css({
                    maxHeight: 25
                  })}
                  src={facebookIcon}
                  alt="Facebook"
                />
              </a>
            </li>
            <li
              key="instagram"
              className="menu-item admin-menu-item"
              {...css({
                padding: mobileMenuVisible ? "10px !important" : 0,
                textAlign: mobileMenuVisible ? "center" : "left"
              })}
            >
              <a
                onClick={() => {
                  navigate("https://www.instagram.com/gatewayresourcelibrary");
                }}
              >
                <img
                  {...css({
                    maxHeight: 29
                  })}
                  src={instagramIcon}
                  alt="Instagram"
                />
              </a>
            </li>
          </Fragment>
        )}
      </GlobalContext.Consumer>
    );
  }
}

const styles = {
  progressContainer: {
    top: -5,
    position: "relative",
    marginRight: 5
  }
};

export default MenuNav;
