import React from "react";
import { Button } from "antd";

import colors from "../styles/globalColors";
import { css } from "./";

export default (props: {
  extraText?: string;
  title: string;
  onViewAll?: Function;
}) => (
  <div {...css(styles.container)}>
    <h2 {...css(styles.sectionHeader)}>{props.title}</h2>
    {props.extraText && (
      <span {...css(styles.extraText)}>{props.extraText}</span>
    )}
    {props.onViewAll && (
      <Button
        size="small"
        onClick={() => props.onViewAll()}
        type="primary"
        shape="round"
        {...css({
          ...styles.viewAllButton,
          "&:hover": {
            backgroundColor: colors.gray
          }
        })}
      >
        View All
      </Button>
    )}
  </div>
);

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15
  },
  extraText: {
    marginRight: 20
  },
  sectionHeader: {
    color: colors.white,
    textTransform: "uppercase" as "uppercase",
    margin: 0,
    marginRight: 20
  },
  viewAllButton: {
    backgroundColor: colors.darkGray,
    border: "none",
    textTransform: "uppercase"
  }
};
