
export const getDocumentUrlWithHttps = (attachment: ResourceAttachment) => {
  if (!attachment || !attachment.documentUrl) return null;

  if (window.location.protocol === 'https:') {
    return (attachment.documentUrl.replace(/^http:\/\//i, 'https://'));
  }

  if (attachment.documentUrl.startsWith('https://cdn.gatewaypeople.com'))

  return attachment.documentUrl;
}

//Temporary hack to get around Gateway CDN CORS issues. See https://teamairship.atlassian.net/browse/VGN-19 for more information
export const getUrlWithDirectCDN = (url: string) => {
  if (!url) return null;

  if (url.startsWith('https://cdn.gatewaypeople.com')) {
    return (url.replace(/^https:\/\/cdn.gatewaypeople.com/i, 'https://gateway-a.akamaihd.net'));
  }

  return url;
}

export const getAuthorName = (author: Author) =>
  author.authorType === "individual"
    ? `${author.firstName} ${author.lastName}`
    : author.organizationName;

export const getOwnerName = (owner: User) =>
  owner && owner.firstName ? `${owner.firstName} ${owner.lastName}` : "";

export const ownerIsStillAdmin = (owner: User, admins: Array<UserAdmin>) =>
  owner && admins && admins.length > 0 && admins.find(admin => admin.id === owner.id);

export const getAuthorsToAdd = (
  allAuthors: Author[],
  prevAuthors: Author[] | null,
  currentAuthorsNames: Array<string>
) => {
  // @ts-ignore
  let notInPrev = [];

  currentAuthorsNames.forEach(author => {
    const fullAuthor = allAuthors.find(a => getAuthorName(a) === author);

    if (
      !prevAuthors ||
      prevAuthors.findIndex(a => getAuthorName(a) === author) < 0
    ) {
      // @ts-ignore
      notInPrev = [...notInPrev, fullAuthor];
    }
  });

  // @ts-ignore
  return notInPrev;
};

export const getAuthorsToRemove = (
  allAuthors: Author[],
  prevAuthors: Author[] | null,
  currentAuthorsNames: Array<string>
) => {
  // @ts-ignore
  let toRemove = [];

  if (prevAuthors) {
    prevAuthors.forEach(author => {
      if (currentAuthorsNames.findIndex(a => a === getAuthorName(author)) < 0) {
        // @ts-ignore
        toRemove = [...toRemove, author];
      }
    });
  }

  // @ts-ignore
  return toRemove;
};

export const isPremiumResource = (item: any) =>
  (item && item.tier && item.tier !== "standard") ||
  (item &&
    item.details &&
    item.details.tier &&
    item.details.tier !== "standard");
