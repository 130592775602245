import React, { Component } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Modal, Switch, Tooltip } from "antd";
import Recaptcha from "react-recaptcha";
import { css, Input } from "./";
import { postSignUp } from "../services/authService";
import { acceptInvite, getTeamInvite } from "../services/teamService";
import { GlobalContext } from "../context/GlobalStateContext";
import { AuthContext } from "../context/AuthContext";
import { sameAsValidation } from "../utils/validationUtils";
import { extractErrorMessage, showErrorNotification } from "../utils/errorUtils";
import globalStyles from "../styles/globalStyles";

type Props = {
  teamInviteSlug: string | null;
  isInLegacyLibrary?: boolean;
};

type State = {
  buttonEnabled: boolean;
  showCaptchaError: boolean;
  loading: boolean;
};

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  organizationName: string;
  postalCode: string;
  password: string;
  passwordConfirmation: string;
  gatewayChurchMember: boolean;
};

class SignUpModal extends Component<Props, State> {
  form: any;
  recaptchaInstance: any;

  state = {
    buttonEnabled: false,
    loading: false,
    showCaptchaError: false
  };

  utmValues = JSON.parse(window.localStorage.getItem("gatewayUtmData"));

  onSignUpAttempt = async (
    values: FormValues,
    setCurrentUserAndAuth: (user: any) => Promise<void>,
    toggleSignUpModal: Function,
    signUpSuccessCallback: Function
  ) => {
    await this.executeCaptcha(this.recaptchaInstance);

    try {
      this.setState({ loading: true });

      // @ts-ignore
      const response = await postSignUp(values, this.props.teamInviteSlug);

      await setCurrentUserAndAuth(response.data);

      let teamInvite;
      if (this.props.teamInviteSlug) {
        teamInvite = await getTeamInvite(this.props.teamInviteSlug);
      }

      if (teamInvite) {
        await acceptInvite(teamInvite.id);
      }

      this.setState({ loading: false });

      this.form.resetForm();

      // if (signUpSuccessCallback) signUpSuccessCallback();

      toggleSignUpModal();

      // NAVIGATE SSO
      window.location.reload();
    } catch (e) {
      showErrorNotification(extractErrorMessage(e));
      this.setState({ loading: false });
    }
  };

  executeCaptcha = function (recaptcha) {
    recaptcha.execute();
  };

  render() {
    return (
      <GlobalContext.Consumer>
        {({ signUpModalVisible, signUpSuccessCallback, teamInviteSlug, toggleSignUpModal }) => (
          <AuthContext.Consumer>
            {({ setCurrentUserAndAuth }) => (
              <Modal visible={signUpModalVisible} onCancel={() => toggleSignUpModal()} title="Sign Up" footer={null}>
                <h3
                  {...css({
                    marginBottom: 20,
                    color: "rgba(0,0,0,0.65)"
                  })}
                >
                  Sign up is easy! This will just take a few moments.
                </h3>
                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    organizationName: "",
                    postalCode: "",
                    password: "",
                    passwordConfirmation: "",
                    gatewayChurchMember: false,
                    signupLibrary: this.props.isInLegacyLibrary ? "legacy" : "resource",
                    utmSource: this.utmValues ? this.utmValues.utm_source : "",
                    utmMedium: this.utmValues ? this.utmValues.utm_medium : "",
                    utmCampaign: this.utmValues ? this.utmValues.utm_campaign : "",
                    utmTerm: this.utmValues ? this.utmValues.utm_term : "",
                    utmContent: this.utmValues ? this.utmValues.utm_content : ""
                  }}
                  ref={(form) => (this.form = form)}
                  onSubmit={(values) =>
                    this.onSignUpAttempt(values, setCurrentUserAndAuth, toggleSignUpModal, signUpSuccessCallback)
                  }
                  validationSchema={teamInviteSlug ? invitationValidationSchema : validationSchema}
                  render={(formProps) => (
                    <div>
                      <form onSubmit={formProps.handleSubmit}>
                        <div {...css({ display: "none", visibility: "hidden" })}>
                          <Input {...formProps} name="utmContent" type="text" />
                        </div>
                        <div {...css({ display: "none", visibility: "hidden" })}>
                          <Input {...formProps} name="utmTerm" type="text" />
                        </div>
                        <div {...css({ display: "none", visibility: "hidden" })}>
                          <Input {...formProps} name="utmCampaign" type="text" />
                        </div>
                        <div {...css({ display: "none", visibility: "hidden" })}>
                          <Input {...formProps} name="utmMedium" type="text" />
                        </div>
                        <div {...css({ display: "none", visibility: "hidden" })}>
                          <Input {...formProps} name="utmSource" type="text" />
                        </div>
                        <div {...css({ display: "none", visibility: "hidden" })}>
                          <Input {...formProps} name="signupLibrary" type="text" />
                        </div>
                        <div {...css({ display: "flex" })}>
                          <Input
                            {...formProps}
                            containerStyle={{ flex: 1, marginRight: 10 }}
                            label="First Name"
                            name="firstName"
                          />
                          <Input {...formProps} containerStyle={{ flex: 1 }} label="Last Name" name="lastName" />
                        </div>
                        <div {...css({ display: "flex" })}>
                          <Input
                            {...formProps}
                            label="Email"
                            name="email"
                            containerStyle={teamInviteSlug ? { flex: 2, marginRight: 10 } : { flex: 1 }}
                          />
                          {teamInviteSlug && (
                            <Input {...formProps} containerStyle={{ flex: 1 }} label="Postal Code" name="postalCode" />
                          )}
                        </div>
                        {!teamInviteSlug && (
                          <div {...css({ display: "flex" })}>
                            <Input
                              {...formProps}
                              label="Organization Name"
                              name="organizationName"
                              containerStyle={{ flex: 2, marginRight: 10 }}
                            />
                            <Input {...formProps} containerStyle={{ flex: 1 }} label="Postal Code" name="postalCode" />
                          </div>
                        )}
                        <div {...css({ display: "flex", flexDirection: "column" })}>
                          <Input
                            {...formProps}
                            containerStyle={{ flex: 1 }}
                            label="Password"
                            name="password"
                            type="password"
                          />
                          <Input
                            {...formProps}
                            containerStyle={{ flex: 1 }}
                            label="Password Confirmation"
                            name="passwordConfirmation"
                            type="password"
                          />
                        </div>
                        <div {...css(styles.inputContainer)}>
                          <label {...css(globalStyles.label)}>
                            Are you a Gateway Church Member?
                          </label>
                          <div {...css(styles.switchContainer)}>
                            <Tooltip title="Are you a Gateway Church Member?">
                              No <Switch
                                // @ts-ignore
                                checked={formProps.values.gatewayChurchMember}
                                size="default"
                                onChange={value =>
                                  formProps.setFieldValue("gatewayChurchMember", value)
                                }
                              /> Yes
                            </Tooltip>
                          </div>
                        </div>
                        <div
                          {...css({
                            ".g-recaptcha > div": { margin: "0 auto 23px" },
                            ".grecaptcha-badge": { visibility: "hidden" }
                          })}
                        >
                          <Recaptcha
                            ref={(e) => (this.recaptchaInstance = e)}
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            size="invisible"
                            verifyCallback={() => {
                              this.setState({ buttonEnabled: true, showCaptchaError: false });
                            }}
                          />
                          {!!this.state.showCaptchaError && (
                            <p style={{ color: "red", width: "100%", textAlign: "center" }}>reCAPTCHA must be valid</p>
                          )}
                        </div>
                        <Button
                          type="primary"
                          size="large"
                          loading={this.state.loading}
                          htmlType="submit"
                          {...css({ width: "100%" })}
                        >
                          Sign Up
                        </Button>
                      </form>
                    </div>
                  )}
                />
              </Modal>
            )}
          </AuthContext.Consumer>
        )}
      </GlobalContext.Consumer>
    );
  }
}

yup.addMethod(yup.mixed, "sameAs", sameAsValidation);

const validationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup.string().email().matches(/^\S*$/, "No spaces allowed").required("Email is required"),
  organizationName: yup.string().required("Organization Name is required"),
  postalCode: yup.string().required("Postal Code is required"),
  password: yup.string().required("Password is required"),
  passwordConfirmation: yup
    .string()
    // @ts-ignore
    .sameAs(yup.ref("password"), "Passwords must match")
    .required("Confirmation is required")
});

const invitationValidationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup.string().email().matches(/^\S*$/, "No spaces allowed").required("Email is required"),
  postalCode: yup.string().required("Postal Code is required"),
  password: yup.string().required("Password is required"),
  passwordConfirmation: yup
    .string()
    // @ts-ignore
    .sameAs(yup.ref("password"), "Passwords must match")
    .required("Confirmation is required")
});

const styles = {
  label: {
    fontSize: 16
  },
  value: {
    fontWeight: 600,
    float: "right" as "right"
  },
  inputContainer: {
    marginTop: 10,
    marginBottom: 30
  },
  switchContainer: {
    marginTop: 5
  }
};

export default SignUpModal;
