import React from "react";
import { Card } from "antd";
import { css } from "./";

export default (props: any) => (
  <Card {...props} bordered={false} {...css([styles.container, props.style])}>
    {props.children}
  </Card>
);

const styles = {
  container: {
    minWidth: 300
  }
};
