import React, { Component } from 'react';
import AuthProvider from './AuthContext';
import GlobalStateProvider from './GlobalStateContext';

class ContextProviders extends Component {
  render() {
    return (
      <GlobalStateProvider>
        <AuthProvider>{this.props.children}</AuthProvider>
      </GlobalStateProvider>
    );
  }
}

export default ContextProviders;
