import { notification } from "antd";
import isObject from "lodash/isObject";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";
import { humanize } from "./generalUtils";

export const extractErrorMessage = (error: any, defaultMessage?: string) => {
  if (error && error.response && error.response.data && error.response.data.error) {
    return error.response.data.error;
  } else if (error && error.response && error.response.data && isObject(error.response.data)) {
    const values = Object.entries(error.response.data).map((k) => `${humanize(k[0])} ${k[1]}`);
    const errorMessages = uniq(flatten(values)).join(". ");
    return errorMessages;
  }

  return defaultMessage || "There was a problem.";
};

export const showErrorNotification = (description: string) => {
  notification.error({
    message: "Error",
    description
  });
};
