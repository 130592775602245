import React, { useContext, useMemo, PropsWithChildren, useEffect } from "react";
import { Layout } from "antd";
import queryString from "query-string";
import { css, cx, Interpolation } from "emotion";
import { useLocation } from "@reach/router";
import {
  Footer,
  ForgotPasswordModal,
  MainHeader,
  Sidebar,
  ShadowUser,
  LoginSignupModal
} from "./";
import { AuthContext } from "../context/AuthContext";
import { GlobalContext } from "../context/GlobalStateContext";
import globalColors from "../styles/globalColors";
import { darkModePages } from "../utils/displayUtils";
import { navigate } from "../utils/routerHistory";
import SearchModal from "./SearchModal";
import { isInLegacyLibrary } from "../utils/generalUtils";

const { Content } = Layout;

type Props = PropsWithChildren<{
  isMobile?: boolean;
  isMobileMenu?: boolean;
}>;

const Container = (props: Props) => {
  const { children } = props;
  const {
    isDarkModeEnabled,
    toggleLoginSignupModal,
    toggleForgotPasswordModal,
    toggleDarkMode
  } = useContext(GlobalContext);
  const authCtx = useContext(AuthContext);
  const { isMobileMenu } = useContext(GlobalContext);
  const location = useLocation();
  const isInAdminSection = location.pathname.startsWith("/admin");
  const legacyLibrary = isInLegacyLibrary();
  const isInProfileSection = location.pathname.startsWith("/profile");
  const isInvitePage = location.pathname.startsWith("/invitation");
  const useSidebarView = isInAdminSection;
  const hideHeaderActions = isInvitePage;

  const hideHeaderSearch =
    location.pathname.startsWith("/search") || location.pathname.startsWith("/legacy-library/search") || location.pathname.startsWith("/profile" );

  useEffect(() => {
    const pathName = location.pathname === "/" ? location.pathname : location.pathname.replace(/\/$/, ""); //replace any trailing "/"
    const isDarkModeAllowed =
      darkModePages.filter((path: string) => {
        if (path.includes(":")) {
          const firstParam = pathName.split("/").slice(-1)[0];
          const secondParam = pathName.split("/").slice(-3)[0];

          const firstPlaceholder = path.split("/").slice(-1)[0];
          const secondPlaceholder = path.split("/").slice(-3)[0];

          let newPath = path.replace(firstPlaceholder, firstParam);
          if (newPath.includes(":")) {
            newPath = newPath.replace(secondPlaceholder, secondParam);
          }

          return newPath === pathName;
        } else {
          return path === pathName;
        }
      }).length === 1;

    if (isDarkModeEnabled !== isDarkModeAllowed) {
      toggleDarkMode();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const headerType: "public" | "admin" | "legacyLibrary" = useMemo(() => {
    if (legacyLibrary) return "legacyLibrary";
    if (isInAdminSection) return "admin";

    return "public";
  }, [legacyLibrary, isInAdminSection]);

  const menuStyle = useMemo(() => {
    if (authCtx.isShadowing) {
      return isMobileMenu ? styles.contentImpersonatingMobile : styles.contentImpersonatingDesktop;
    } else {
      return isMobileMenu ? styles.contentMobile : styles.contentDesktop;
    }
  }, [authCtx.isShadowing, isMobileMenu]);

  const menuAdminStyle = useMemo(() => {
    if (!isInAdminSection) return null;

    if (authCtx.isShadowing) {
      return isMobileMenu ? styles.contentImpersonatingMobileAdmin : styles.contentImpersonatingDesktopAdmin;
    } else {
      return isMobileMenu ? styles.contentMobileAdmin : styles.contentDesktopAdmin;
    }
  }, [authCtx.isShadowing, isInAdminSection, isMobileMenu]);

  const contentStyle = useMemo(() => {
    if (isDarkModeEnabled) {
      return styles.contentDark;
    }

    return styles.content;
  }, [isDarkModeEnabled]);

  const publicContainerStyle = useMemo(() => {
    if (isDarkModeEnabled) {
      return styles.containerPublicDark;
    }

    return styles.containerPublic;
  }, [isDarkModeEnabled]);

  useEffect(() => {
    if (location.search) {
      const query = queryString.parse(location.search);
      if (query && query.signup) {
        //navigate(location.pathname);
        // toggleLoginSignupModal();
      } else if (query && query.login) {
        //navigate(location.pathname);
        // toggleLoginSignupModal();
      } else if (query && query.forgotPassword) {
        //navigate(location.pathname);
        // toggleForgotPasswordModal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GlobalContext.Consumer>
      {({ teamInviteSlug, isMobileMenu }) => (
        <AuthContext.Consumer>
          {() => {
            return (
              <>
                <ShadowUser />
                <Layout
                  className={cx(css(styles.container), {
                    [css(publicContainerStyle)]: !isInAdminSection && !isInProfileSection
                  })}
                >
                  {useSidebarView ? (
                    <Sidebar isInLegacyLibrary={legacyLibrary} type="admin" isMobile={isMobileMenu} />
                  ) : null}
                  <Layout
                    className={
                      isInAdminSection || isInProfileSection
                        ? css(styles.stickySectionOverride)
                        : css(publicContainerStyle)
                    }
                  >
                    <MainHeader
                      hideActions={hideHeaderActions}
                      hideSearch={hideHeaderSearch}
                      isMobileMenu={isMobileMenu}
                      headerType={headerType}
                      useSidebarView={useSidebarView}
                      darkMode={isDarkModeEnabled}
                    />

                    <Content className={css(contentStyle, menuStyle, menuAdminStyle)}>{children}</Content>
                    <Footer isMobile={isMobileMenu} darkMode={isDarkModeEnabled} />
                  </Layout>
                  <ForgotPasswordModal />
                  <LoginSignupModal />
                  <SearchModal />
                </Layout>
              </>
            );
          }}
        </AuthContext.Consumer>
      )}
    </GlobalContext.Consumer>
  );
};

const styles: Record<
  | "container"
  | "containerPublic"
  | "containerPublicDark"
  | "content"
  | "contentDark"
  | "contentDesktop"
  | "contentImpersonatingDesktop"
  | "contentMobile"
  | "contentImpersonatingMobile"
  | "contentDesktopAdmin"
  | "contentImpersonatingDesktopAdmin"
  | "contentMobileAdmin"
  | "contentImpersonatingMobileAdmin"
  | "stickySectionOverride",
  Interpolation
> = {
  container: {
    minHeight: "100vh"
  },
  containerPublic: {
    backgroundColor: globalColors.white
  },
  containerPublicDark: {
    backgroundColor: globalColors.darkestGray,
    color: globalColors.white
  },
  content: {
    margin: "0"
  },
  contentDark: {
    backgroundColor: globalColors.darkestGray,
    color: globalColors.white,
    margin: "0"
  },
  contentDesktop: {
    paddingTop: 75
  },
  contentImpersonatingDesktop: {
    paddingTop: 115
  },
  contentMobile: {
    paddingTop: 75
  },
  contentImpersonatingMobile: {
    paddingTop: 135
  },
  contentDesktopAdmin: {
    paddingTop: 85
  },
  contentImpersonatingDesktopAdmin: {
    paddingTop: 125
  },
  contentMobileAdmin: {
    paddingTop: 75
  },
  contentImpersonatingMobileAdmin: {
    paddingTop: 145
  },
  stickySectionOverride: {
    // required for the sticky editing section to work
    overflowX: "initial !important"
    // eslint-disable-next-line
  } as any
};

export default Container;
