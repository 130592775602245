export default {
  black: "#111111",
  lightGray: "#646464",
  darkGray: "#474849",
  darkerGray: "#212223",
  darkestGray: "#131415",
  blue: "#111111",
  gray: "#7f7f7f",
  green: "#52c41a",
  placeholder: "#f3f3f3",
  red: "#F6511B",
  teal: "#32BEAE",
  yellow: "#FABC00",
  white: "#fff",
  footer: "#111111",
  hoverWhite: "rgba(255, 255, 255, 0.7)",
  hoverDark: "#3c3d41",
  textLight: "#fff",
  textDark: "#000",
  adminBackground: "#f0f2f5"
};
