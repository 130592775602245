import React, { Component } from "react";
import ReactGA from "react-ga";
// import { hotjar } from "react-hotjar";
import Router from "./routes/index.js";
import ContextProviders from "./context/index";
import queryString from "query-string";
import { isInLegacyLibrary } from "./utils/generalUtils";

class App extends Component {
  componentDidMount() {
    if (isInLegacyLibrary()) {
      // Uncomment when react-ga will support Google Analytics 4
      // ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_LEGACY_ID);
    } else {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }

    // Parse URL query string for UTM params.
    const queryParams = queryString.parse(window.location.search);

    // Verify UTM source is provided.
    if (queryParams && queryParams.utm_source) {
      // Store UTM data for later use.
      const stringOfQueryParams = JSON.stringify(queryParams);

      window.localStorage.setItem("gatewayUtmData", stringOfQueryParams);

      // TODO - SSO - THIS NEEDS TO MOVE TO SSO FOR SETTING UTM VALUES
      // Set iFrame's UTM Params.
      // const iframe = document.getElementById("cross-domain-auth") as HTMLIFrameElement;
      // iframe.contentWindow.postMessage(
      //   {
      //     action: "setUtmValues",
      //     value: stringOfQueryParams
      //   },
      //   "*"
      // );
    }

    if (isInLegacyLibrary()) {
      document.title = "Gateway Legacy Library";
    } else {
      document.title = "Gateway Resource Library";
    }
  }

  render() {
    return (
      <div>
        <ContextProviders>
          <Router />
        </ContextProviders>
      </div>
    );
  }
}

export default App;
