import API from "./baseService";

type ChurchBody = {
  name?: string;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
  country?: string | null;
  phone?: string | null;
  website?: string | null;
  memberLimit?: number;
};

export const getAllChurches = (page: number, perPage: number) =>
  API.get(`/churches?page=${page}&per_page=${perPage}`);

export const searchChurches = (
  page: number,
  perPage: number,
  searchValue: string
) =>
  API.post(`/churches/search?page=${page}&per_page=${perPage}`, {
    search: { name: searchValue }
  });

export const getChurch = (id: number) =>
  API.get(`/churches/${id}`).then(response => response.data);

export const getMyTeam = () =>
  API.get("/my_team").then(response => response.data);

export const createChurch = (body: ChurchBody) =>
  API.post("/churches", {
    church: { ...body, address_1: body.address1, address_2: body.address2 }
  }).then(response => response.data);

export const updateChurch = (id: number, body: ChurchBody) =>
  API.put(`/churches/${id}`, {
    church: { ...body, address_1: body.address1, address_2: body.address2 }
  }).then(response => response.data);

export const deleteChurch = (id: number) =>
  API.delete(`/churches/${id}`).then(response => response.data);

export const getMyTeamInvitees = () =>
  API.get("/my_team/invites").then(response => response.data);

export const getMyTeamInvites = () =>
  API.get("/invites/my_team_invites").then(response => response.data);

export const getTeamInvite = (slug: string) =>
  API.get(`/invites/my_team_invites/${slug}`).then(response => response.data);

export const acceptInvite = (inviteId: number) =>
  API.patch(`/team_invitations/${inviteId}`).then(response => response.data);

export const acceptInviteSlug = (teamInviteSlug: string) =>
  API.patch(`/invites/accept_team_slug/${teamInviteSlug}`).then(
    response => response.data
  );

export const rejectInvite = (inviteId: number) =>
  API.patch(`/invites/decline_team_invite/${inviteId}`).then(
    response => response.data
  );

export const rejectInviteSlug = (teamInviteSlug: string) =>
  API.patch(`/invites/decline_team_slug/${teamInviteSlug}`).then(
    response => response.data
  );

export const createInvite = (teamInvitation: {
  inviteeEmail: string;
  inviteeName: string;
  teamId: number;
}) =>
  API.post("/team_invitations", {
    teamInvitation
  }).then(response => response.data);

export const deleteInvite = (inviteId: number) =>
  API.delete(`/team_invitations/${inviteId}`).then(response => response.data);

// For System Admin use only
export const updateTeamMemberRole = (userId: number, role: ChurchMemberRole) =>
  API.patch(`/team_members/${userId}`, { team_member: { role } });

// For System Admin use only
export const deleteTeamMember = (userId: number) =>
  API.delete(`/team_members/${userId}`);

export const removeTeamMember = (userId: number) =>
  API.delete("/my_team/remove_member", { data: { member: { userId } } });

export const makeTeamMemberAdmin = (userId: number) =>
  API.patch("/my_team/make_member_admin", { member: { userId } });

export const demoteTeamMember = (userId: number) =>
  API.patch("/my_team/demote_member_admin", { member: { userId } });
