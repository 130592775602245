import React, { forwardRef } from "react";
import { Player, BigPlayButton } from "video-react";
import { Interpolation } from "emotion";
import css from "./css";

type Props = {
  poster: string | null;
  src: string | null;
  ikonikId: string | null;
  isMobile: boolean;
  autoPlay?: boolean;
  className?: string;
};

const VideoPlayer = forwardRef(function VideoPlayerForwarded(props: Props, ref) {
  const { autoPlay = true, src, ikonikId, poster, className } = props;

  if (!src && !ikonikId) {
    if (poster) {
      return (
        <Player ref={ref} autoPlay={false} playsInline poster={poster} src={""} {...css(styles.disabled)}></Player>
      );
    } else {
      return null;
    }
  }

  const player = (srcLink: string) => (
    <Player
      ref={ref}
      {...css(className, !src && styles.disabled)}
      autoPlay={autoPlay}
      playsInline
      poster={poster}
      src={srcLink}
      disabled
    >
      <BigPlayButton position="center" />
    </Player>
  );

  if (ikonikId) {
    const ikonikLink = `https://player.theplatform.com/p/IfSiAC/RDjjChFoUSK8/select/media/${ikonikId}?form=html`;
    player(ikonikLink);
  }

  return player(src);
});

const styles: Record<"disabled", Interpolation> = {
  disabled: {
    "&.video-react .video-react-poster": {
      cursor: "default"
    }
  }
};

export default VideoPlayer;
