import React from "react";
import { css } from "emotion";
import { FormikProps } from "formik";
import upperFirst from "lodash/upperFirst";
import { debounce } from "lodash";
import { DatePicker, Form, Icon, Input, Select, Tooltip } from "antd";
import MaskedInput from "react-text-mask";
import colors from "../styles/globalColors";
import globalStyles from "../styles/globalStyles";

const Option = Select.Option;

const AntdInput = (
  props: FormikProps<any> & {
    birthdate?: boolean;
    containerStyle?: any;
    datePicker?: boolean;
    allowEmptySelectOption?: boolean;
    autoCapitalize?: string;
    icon?: any;
    label?: string;
    maxLength?: number;
    name: string;
    onChange?: any;
    phone?: boolean;
    placeholder?: string;
    rows?: number;
    select?: boolean;
    selectOptions?: any;
    showSearch?: boolean;
    textArea?: boolean;
    tooltip?: string;
    type?: any;
    value?: any;
    disabled?: boolean;
    dateFormat?: string;
  }
) => {
  const {
    containerStyle,
    birthdate,
    datePicker,
    allowEmptySelectOption,
    autoCapitalize,
    errors,
    handleChange,
    handleBlur,
    icon,
    label,
    maxLength,
    name,
    onChange,
    placeholder,
    phone,
    // required,
    rows,
    select,
    selectOptions,
    setFieldTouched,
    setFieldValue,
    showSearch,
    textArea,
    tooltip,
    touched,
    type,
    value,
    values,
    disabled,
    dateFormat
  } = props;

  const datePickerRef = React.useRef(null);
  const [datePickerWidth, setDatePickerWidth] = React.useState(280);

  const hasError = !!(touched[name] && errors[name]);

  const InputComponent: any = textArea ? Input.TextArea : Input;

  const inputValue = value || values[name] || "";

  const phoneMask = ["(", /\d/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
  const birthdateMask = [/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

  const defaultDateFormat = dateFormat || "MM-DD-YYYY";

  let optionsForSelect =
    selectOptions &&
    selectOptions.map((option: any) => {
      if (typeof(option) === 'object') {
        return (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        )
      } else {
        return (
          <Option key={option} value={option}>
            {upperFirst(option)}
          </Option>
        )
      }
    });

  if (optionsForSelect && allowEmptySelectOption) {
    optionsForSelect = [
      <Option key={""} value={""}>
        - None -
      </Option>,
      ...optionsForSelect
    ];
  }

  React.useEffect(() => {
    if (datePicker && datePickerRef.current) {
      const handleScreenResize = () => {
        const width = datePickerRef.current.picker.input.offsetWidth;
        setDatePickerWidth(width < 200 ? 200 : width);
      };

      handleScreenResize();

      const debouncedResize = debounce(handleScreenResize, 100);
      window.addEventListener("resize", debouncedResize);

      return () => window.removeEventListener("resize", debouncedResize);
    }
  }, []);

  return (
    <div className={css({ ...containerStyle })}>
      {label ? (
        tooltip ? (
          <Tooltip title={tooltip}>
            <label htmlFor={name} className={css(globalStyles.label)}>
              {label}
            </label>
          </Tooltip>
        ) : (
          <label htmlFor={name} className={css(globalStyles.label)}>
            {label}
          </label>
        )
      ) : null}
      <Form.Item
        hasFeedback={hasError}
        validateStatus={hasError ? "error" : undefined}
        help={hasError ? errors[name] : null}
      >
        {select && selectOptions ? (
          <Select
            className={css(styles.fullWidth)}
            placeholder={placeholder}
            value={inputValue}
            showSearch={showSearch}
            disabled={disabled}
            size="large"
            showAction={["click", "focus"]}
            onBlur={() => setFieldTouched(name)}
            onChange={
              onChange
                ? onChange
                : (value) => {
                    setFieldTouched(name);
                    setFieldValue(name, value);
                  }
            }
          >
            {optionsForSelect}
          </Select>
        ) : datePicker ? (
          <DatePicker
            ref={datePickerRef}
            className={css(styles.fullWidth)}
            popupStyle={{
              width: `${datePickerWidth}px`
            }}
            format={[defaultDateFormat, "M-D-YYYY", "MM-DD-YYYY", "M/D/YYYY", "MMDDYYYY"]}
            onChange={(date) => setFieldValue(name, date)}
            size="large"
            disabled={disabled}
            value={inputValue}
          />
        ) : birthdate ? (
          <MaskedInput
            autoCapitalize={autoCapitalize}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={placeholder}
            value={inputValue}
            mask={birthdateMask}
            showMask={true}
            guide={true}
            className="ant-input ant-input-lg"
            disabled={disabled}
          />
        ) : phone ? (
          <MaskedInput
            autoCapitalize={autoCapitalize}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={placeholder}
            value={inputValue}
            mask={phoneMask}
            showMask={true}
            guide={true}
            className="ant-input ant-input-lg"
            disabled={disabled}
          />
        ) : (
          <InputComponent
            autoCapitalize={autoCapitalize}
            size="large"
            maxLength={maxLength}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            rows={rows}
            type={type}
            placeholder={placeholder}
            prefix={icon ? <Icon type={icon} style={{ color: "rgba(0,0,0,.25)" }} /> : null}
            value={inputValue}
            disabled={disabled}
          />
        )}
      </Form.Item>
    </div>
  );
};

const styles = {
  fullWidth: {
    width: "100%"
  },
  error: {
    color: colors.red
  }
};

export default AntdInput;
