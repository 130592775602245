import API from "./baseService";

export const getUsers = (page: number, perPage: number) =>
  API.get(`/users?page=${page}&per_page=${perPage}`);

export const searchUsers = (
  page: number,
  perPage: number,
  searchValue: string
) =>
  API.post(`/users/search?page=${page}&per_page=${perPage}`, {
    search: { display_name: searchValue }
  });

export const getAdmins = () =>
  API.get("/users/admins").then(response => response.data);

export const getUser = (id: number) =>
  API.get(`/users/${id}`).then(response => response.data);

export const getUserInvoices = (id: number) =>
  API.get(`/users/${id}/invoices`).then(response => response.data);

export const updateUser = (
  id: number,
  body: {
    firstName?: string;
    lastName?: string;
    role?: string;
    birthdate?: string | null;
    churchRole?: string;
    churchTitle?: string;
    email?: string;
    phone?: string;
    mobilePhone?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    country?: string;
  }
) =>
  API.put(`/users/${id}`, {
    user: { ...body, address_1: body.address1, address_2: body.address2 }
  }).then(response => response.data);
