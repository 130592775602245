import React, { Component } from "react";
import withSizes from "react-sizes";

type Props = {
  children: JSX.Element;
  isMobile: boolean;
  isMobileMenu: boolean;
  isMediumScreen: boolean;
  isLessThan1300: boolean;
  isLessThan855: boolean;
};

type State = {
  forgotPasswordEmail: string;
  forgotPasswordModalVisible: boolean;
  teamInviteSlug: string | null;
  loginSignupModalVisible: boolean;
  loginModalVisible: boolean;
  loginSuccessCallback: () => void | null;
  mobileMenuVisible: boolean;
  settingsMenuVisible: boolean;
  settingsModalVisible: boolean;
  signUpModalVisible: boolean;
  signUpSuccessCallback: () => void | null;
  signUpSuccessCallbackAlt: () => void | null;
  loginSignupSuccessCallback: () => void | null;
  toggleForgotPasswordModal: (email?: string) => void;
  toggleLoginModal: (successCallback?: () => void) => void;
  toggleMobileMenu: () => void;
  toggleSettingsModal: () => void;
  toggleSignUpModal: (teamInviteSlug?: string, successCallback?: () => void) => void;
  toggleSignUpModalAlt: (successCallback?: () => void) => void;
  toggleLoginSignupModal: () => void;
  toggleSearchModal: () => void;
  toggleDarkMode: () => void;
  isSearchModalVisible: boolean;
  isDarkModeEnabled: boolean;
};

export const GlobalContext = React.createContext(
  {} as State & {
    isMobile: boolean;
    isMobileMenu: boolean;
    isMediumScreen: boolean;
    isLessThan1300: boolean;
    isLessThan855: boolean;
    loginSuccessCallback: () => void | null;
  }
);

class GlobalStateContext extends Component<Props, State> {
  toggleMobileMenu = () =>
    this.setState(({ mobileMenuVisible }) => ({
      mobileMenuVisible: !mobileMenuVisible
    }));

  toggleLoginSignupModal = () =>
    this.setState(({ loginSignupModalVisible }) => ({
      loginSignupModalVisible: !loginSignupModalVisible
    }));
  toggleSearchModal = () =>
    this.setState(({ isSearchModalVisible }) => ({
      isSearchModalVisible: !isSearchModalVisible
    }));

  toggleLoginModal = (successCallback?: () => void) =>
    this.setState(({ loginSignupModalVisible }) => ({
      loginSignupModalVisible: !loginSignupModalVisible
    }));

  toggleSettingsModal = () =>
    this.setState(({ settingsMenuVisible }) => ({
      settingsMenuVisible: !settingsMenuVisible
    }));

  toggleForgotPasswordModal = (email?: string) =>
    this.setState(({ forgotPasswordModalVisible }) => ({
      forgotPasswordEmail: email || "",
      forgotPasswordModalVisible: !forgotPasswordModalVisible
    }));

  toggleSignUpModal = (teamInviteSlug?: string, successCallback?: () => void) =>
    this.setState(({ loginSignupModalVisible }) => ({
      loginSignupModalVisible: !loginSignupModalVisible
    }));

  toggleSignUpModalAlt = (successCallback?: () => void) =>
    this.setState(({ loginSignupModalVisible }) => ({
      loginSignupModalVisible: !loginSignupModalVisible
    }));

  toggleDarkMode = () => this.setState(({ isDarkModeEnabled }) => ({ isDarkModeEnabled: !isDarkModeEnabled }));

  state = {
    forgotPasswordEmail: "",
    forgotPasswordModalVisible: false,
    teamInviteSlug: null,
    loginSignupModalVisible: false,
    loginModalVisible: false,
    loginSuccessCallback: null,
    mobileMenuVisible: false,
    settingsMenuVisible: false,
    settingsModalVisible: false,
    signUpModalVisible: false,
    isSearchModalVisible: false,
    isDarkModeEnabled: true,
    signUpSuccessCallback: null,
    signUpSuccessCallbackAlt: null,
    loginSignupSuccessCallback: null,
    toggleForgotPasswordModal: this.toggleForgotPasswordModal,
    toggleLoginModal: this.toggleLoginModal,
    toggleMobileMenu: this.toggleMobileMenu,
    toggleSettingsModal: this.toggleSettingsModal,
    toggleSignUpModal: this.toggleSignUpModal,
    toggleSignUpModalAlt: this.toggleSignUpModalAlt,
    toggleLoginSignupModal: this.toggleLoginSignupModal,
    toggleSearchModal: this.toggleSearchModal,
    toggleDarkMode: this.toggleDarkMode
  };

  render() {
    return (
      <GlobalContext.Provider
        value={{
          ...this.state,
          isMobile: this.props.isMobile,
          isMobileMenu: this.props.isMobileMenu,
          isMediumScreen: this.props.isMediumScreen,
          isLessThan1300: this.props.isLessThan1300,
          isLessThan855: this.props.isLessThan855
        }}
      >
        {this.props.children}
      </GlobalContext.Provider>
    );
  }
}

const mapSizesToProps = ({ width }: { width: number }) => ({
  isMobile: width < 575,
  isMobileMenu: width < 981,
  isMediumScreen: width >= 575 && width <= 1024,
  isLessThan1300: width < 1300,
  isLessThan855: width < 855
});

export default withSizes(mapSizesToProps)(GlobalStateContext);
