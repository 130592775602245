import moment from "moment";

/* eslint func-names: 0 */
export const validPasswordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
export const validPhone = /\d{10}/gi; //10 digits for phone number

//Remove spaces and the following characters ()-_
export const unmaskedPhone = (value) => 
  value.replace(/_/gi,'')
       .replace(/-/gi,'')
       .replace(/ /gi,'')
       .replace(/\(/gi,'')
       .replace(/\)/gi,'');

export const unmaskedBirthdate = (value) => 
  value.replace(/_/gi,'')
       .replace(/-/gi,'');

export const birthdateValidation = function(message: string) {
  // @ts-ignore
  return this.test({
    name: "birthdate",
    exclusive: true,
    message,
    // @ts-ignore
    test: value => {
      try {
        if (!value) return true;

        const birthdate = unmaskedBirthdate(value);
        if (birthdate === '') return true; //Empty string return true, let the required validator fail it if it's required

        return moment(value, "MM-DD-YYYY", true).isValid();
      } catch (e) {
        return false;
      }
    }
  });
};

export const phoneValidation = function(message: string) {
  // @ts-ignore
  return this.test({
    name: "phone",
    exclusive: true,
    message,
    // @ts-ignore
    test: value => {
      try {
        if (!value) return true;

        const phone = unmaskedPhone(value);
        if (phone === '') return true; //Empty string return true, let the required validator fail it if it's required
        return phone.match(validPhone) != undefined;
      } catch (e) {
        return false;
      }
    }
  });
};

export const sameAsValidation = function(ref: string, message: string) {
  // @ts-ignore
  return this.test("sameAs", message, function(value) {
    // @ts-ignore
    const other = this.resolve(ref);
    return !other || !value || value === other;
  });
};
