import React, { Component } from "react";
import ReactAudioPlayer from "react-audio-player";

type Props = {
  src: string | null;
};

export default class extends Component<Props> {
  render() {
    if (!this.props.src) return null;

    return <ReactAudioPlayer src={this.props.src} autoPlay controls />;
  }
}
