import React, { CSSProperties, SyntheticEvent, PropsWithChildren } from "react";
import { Icon, Tooltip } from "antd";
import { css } from "./";
import globalStyles from "../styles/globalStyles";

type Props = {
  disabled?: boolean;
  icon?: string;
  iconColor?: string;
  onClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  style?: CSSProperties;
  theme?: "filled" | "outlined" | "twoTone";
  tooltipPlacement?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom";
  tooltipTitle?: string;
};

const CardHeaderIcon = (props: PropsWithChildren<Props>) => (
  <Tooltip title={props.tooltipTitle} placement={props.tooltipPlacement || "bottomLeft"}>
    <span {...(props.style ? css(props.style) : null)}>
      <button type="button" {...css(globalStyles.button)} onClick={props.disabled ? undefined : props.onClick}>
        <Icon
          style={{
            fontSize: 22,
            color: props.iconColor || "rgba(0, 0, 0, 0.65)"
          }}
          theme={props.theme || "outlined"}
          type={props.icon || "edit"}
        />
        {props.children}
      </button>
    </span>
  </Tooltip>
);

export default CardHeaderIcon;
