import algoliasearch from "algoliasearch/lite";
import API from "./baseService";

// Direct to Algolia serach
export const algoliaSearch = (params: {
  callback: (error: Error, content: algoliasearch.Response) => void;
  filters?: string;
  hitsPerPage?: number;
  searchIndex?: "published" | "system" | "editor";
  query?: string;
}) => {
  const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_SEARCH_KEY);
  const index = client.initIndex(
    params.searchIndex === "system"
      ? process.env.REACT_APP_ALGOLIA_SYSTEM_INDEX
      : params.searchIndex === "editor"
      ? process.env.REACT_APP_ALGOLIA_EDITOR_INDEX
      : process.env.REACT_APP_ALGOLIA_PUBLISHED_INDEX
  );

  index.search(
    {
      query: params.query || "",
      filters: params.filters,
      hitsPerPage: params.hitsPerPage
    },
    params.callback
  );
};

// Go through API in order to get full item models back as well as viewed/favorited
export const apiAlgoliaSearch = (params: {
  filters?: string[];
  hitsPerPage?: number;
  page?: number;
  searchIndex?: string;
  query?: string;
}) =>
  API.post("search", {
    noTransformRequest: true,
    query: params.query || "",
    search: {
      index: params.searchIndex,
      facetFilters: params.filters,
      page: params.page,
      hitsPerPage: params.hitsPerPage
    }
  }).then((response) => response.data);

export const apiLegacyAlgoliaSearch = (params: {
  filters?: string[][];
  hitsPerPage?: number;
  page?: number;
  searchIndex?: string;
  query?: string;
}) =>
  API.post("search", {
    noTransformRequest: true,
    query: params.query || "",
    library_type: "Legacy",
    search: {
      index: params.searchIndex,
      facetFilters: params.filters,
      page: params.page,
      hitsPerPage: params.hitsPerPage
    }
  }).then((response) => response.data);
