import React from 'react';
import { Button, Tooltip } from 'antd';
import { css } from 'emotion';

export default ({
  canPublish,
  isComplete,
  isPublished,
  loading,
  onPublishPress
}) => {
  if (loading) return null;

  const buttonDisabled = !canPublish || !isComplete;

  const text = isPublished ? 'Unpublish' : 'Publish';
  const type = isPublished ? 'danger' : 'primary';
  const size = isPublished ? 'default' : 'large';

  let tooltipTitle = null;
  if (!canPublish) {
    tooltipTitle = 'Your role does not allow this action.';
  } else if (!isComplete) {
    tooltipTitle = 'Complete all of the section in order to publish.';
  }

  return (
    <div className={css(styles.publishContainer)}>
      <Tooltip title={tooltipTitle} placement="bottomRight">
        <Button
          disabled={buttonDisabled}
          size={size}
          type={type}
          onClick={() => onPublishPress(isPublished)}
        >
          {text}
        </Button>
      </Tooltip>
    </div>
  );
};

const styles = {
  publishContainer: {
    paddingLeft: 24
  }
};
