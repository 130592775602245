import React from "react";
import { Tooltip } from "antd";
import { css } from "emotion";
import { PositionProperty } from "csstype";

export default (props: { showOverlay: boolean; title: string }) => {
  if (!props.showOverlay) return null;

  return (
    <Tooltip title={props.title} placement="topLeft">
      <div className={css(styles.disabledOverlay)} />
    </Tooltip>
  );
};

const styles = {
  disabledOverlay: {
    position: "absolute" as PositionProperty,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(189, 189, 189, 0.57)",
    zIndex: 1
  }
};
