import axios from "axios";
import camelCaseKeys from "camelcase-keys-deep";
import snakeCaseKeys from "decamelize-keys-deep";
import { CURRENT_USER_KEY, AUTH_TOKEN_KEY, REFRESH_TOKEN_KEY, SHADOW_USER_ID_KEY } from "../context/AuthContext";
import { showErrorNotification } from "../utils/errorUtils";
import { isInLegacyLibrary } from "../utils/generalUtils";
import { navigate } from "../utils/routerHistory";
import { clearStorage } from "../utils/localstorage";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/v1",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  transformRequest: [
    (data, headers) => {
      const authToken = JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY));
      const shadowUserId = JSON.parse(localStorage.getItem(SHADOW_USER_ID_KEY));

      if (authToken) {
        headers.common.Authorization = authToken;
      }

      if (shadowUserId) {
        headers.common.Shadow = shadowUserId;
      }

      if (typeof data === "object" && data !== null) {
        const { noTransformRequest, ...rest } = data;

        if (noTransformRequest) {
          return JSON.stringify(rest);
        } else {
          return JSON.stringify(snakeCaseKeys(rest));
        }
      }

      return data;
    }
  ],
  transformResponse: [
    (data) => {
      if (data && data !== " " && !(data instanceof Blob)) {
        return camelCaseKeys(JSON.parse(data));
      } else {
        return data;
      }
    }
  ]
});

let isRefreshing = false;
let subscribers = [];

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

function onRefreshed(token) {
  subscribers.map((cb) => cb(token));
  isRefreshing = false;
  subscribers = [];
}

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { config, response } = error;
    const originalRequest = config;

    if (
      response &&
      response.status === 401 &&
      response.data &&
      response.data.authorization &&
      response.data.authorization[0].includes("expire")
    ) {
      if (!isRefreshing) {
        isRefreshing = true;

        const refreshToken = JSON.parse(localStorage.getItem(REFRESH_TOKEN_KEY));

        if (refreshToken) {
          axiosInstance
            .post("/refresh_auth", undefined, {
              headers: {
                AUTH_REFRESH: refreshToken
              }
            })
            .then((response) => {
              localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(response.data.user))

              localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(response.data.token.auth));

              localStorage.setItem(REFRESH_TOKEN_KEY, JSON.stringify(response.data.token.refresh));

              onRefreshed(response.data.token.auth);

              // No bouncing for SSO on a token refresh, otherwise user can lose location. 
              // Let customer usage prove otherwise. 
            })
            .catch(() => {
              clearAndLogout();
            });
        } else {
          clearAndLogout();
        }
      }

      const requestSubscribers = new Promise((resolve) => {
        subscribeTokenRefresh((authToken) => {
          originalRequest.headers.Authorization = authToken;

          resolve(axios(originalRequest));
        });
      });

      return requestSubscribers;
    } else if (response && response.status === 401 && config.url.indexOf("/authenticate") === -1) {
      showErrorNotification("You do not have permission to view this content.");

      if (isInLegacyLibrary()) {
        navigate(process.env.REACT_APP_LEGACY_LIBRARY_DOMAIN);
      } else {
        navigate(process.env.REACT_APP_DOMAIN);
      }
    }

    return Promise.reject(error);
  }
);

const clearAndLogout = () => {
  clearStorage();

  // logoutCallback is created in `AuthContext.js`
  (window as any).logoutCallback(() => window.location.reload());

  showErrorNotification("Your session is no longer valid. Please login again.");
};

export default axiosInstance;
