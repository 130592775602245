import React from "react";
import { Tooltip } from "antd";
import colors from "../styles/globalColors";
import { css } from "./";

type Props = {
  viewed: boolean;
};

const ViewedCircle = (props: Props) => {
  return (
    <Tooltip title={props.viewed ? "Viewed" : "Not Viewed"} placement="top">
      <div
        {...css([styles.circleViewed, !props.viewed && styles.circleUnviewed])}
      />
    </Tooltip>
  );
};

const styles = {
  circleViewed: {
    marginRight: 15,
    backgroundColor: colors.blue,
    height: 16,
    width: 16,
    borderRadius: 8
  },
  circleUnviewed: {
    border: `1px solid ${colors.blue}`,
    backgroundColor: colors.white
  }
};

export default ViewedCircle;
