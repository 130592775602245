import React from "react";
import { Button, Tooltip } from "antd";
import { css } from "emotion";

export default ({
  canDelete,
  hide,
  onPress,
  text
}: {
  canDelete: boolean;
  hide: boolean;
  onPress: Function;
  text: string;
}) => {
  if (hide) return null;

  const tooltipTitle = canDelete
    ? null
    : "Your role does not allow this action";

  return (
    <div className={css(styles.deleteContainer)}>
      <Tooltip title={tooltipTitle} placement="topRight">
        <Button disabled={!canDelete} type="dashed" onClick={() => onPress()}>
          {text || "Delete"}
        </Button>
      </Tooltip>
    </div>
  );
};

const styles = {
  deleteContainer: {
    marginBottom: 24
  }
};
