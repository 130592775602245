import React, { Component } from "react";
import { css } from "emotion";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Modal } from "antd";
import { Input } from "./";
import { GlobalContext } from "../context/GlobalStateContext";
import { sendResetPasswordEmail } from "../services/authService";
import { extractErrorMessage, showErrorNotification } from "../utils/errorUtils";
import { showSuccessNotification } from "../utils/generalUtils";

type State = {
  loading: boolean;
};

type FormProps = {
  email: string;
};

class ForgotPasswordModal extends Component<unknown, State> {
  form: any;

  state = {
    loading: false
  };

  onResetAttempt = async (values: FormProps, toggleForgotPasswordModal: (email?: string) => void) => {
    try {
      this.setState({ loading: true });
      await sendResetPasswordEmail(values.email.toLowerCase());

      this.setState({ loading: false });
      this.form.resetForm();
      showSuccessNotification({
        message: "Reset Password Email Sent!"
      });
      toggleForgotPasswordModal();
    } catch (e) {
      showErrorNotification(extractErrorMessage(e));
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <GlobalContext.Consumer>
        {({ forgotPasswordEmail, forgotPasswordModalVisible, toggleForgotPasswordModal }) => (
          <Modal
            visible={forgotPasswordModalVisible}
            onCancel={() => toggleForgotPasswordModal()}
            title="Forgot Password"
            footer={null}
          >
            <p>Enter your email and we will send you a link to reset your password.</p>
            <Formik
              initialValues={{
                email: forgotPasswordEmail || ""
              }}
              enableReinitialize
              ref={(form) => (this.form = form)}
              onSubmit={(values) => this.onResetAttempt(values, toggleForgotPasswordModal)}
              validationSchema={yup.object().shape({
                email: yup.string().email().matches(/^\S*$/, "No spaces allowed").required("Email is required")
              })}
              render={(formProps) => (
                <div>
                  <form onSubmit={formProps.handleSubmit}>
                    <Input {...formProps} icon="user" name="email" placeholder="Email" />
                    <Button
                      type="primary"
                      size="large"
                      loading={this.state.loading}
                      htmlType="submit"
                      className={css(styles.button)}
                    >
                      Send Reset Password Link
                    </Button>
                  </form>
                </div>
              )}
            />
          </Modal>
        )}
      </GlobalContext.Consumer>
    );
  }
}

const styles = {
  button: {
    width: "100%"
  }
};

export default ForgotPasswordModal;
