export default {
  admin: {
    authors: "/admin/authors",
    categories: "/admin/categories",
    churches: "/admin/churches",
    copyrights: "/admin/copyrights",
    dashboard: "/admin/dashboard",
    dataCleanupLogs: "/admin/data-cleanup-logs",
    featuredBanners: "/admin/featured-banners",
    legacyLibrary: {
      seriesIndex: "/admin/legacy-library/series",
      series: "/admin/legacy-library/series/:id",
      seriesNew: "/admin/legacy-library/series/new",
      themes: "/admin/legacy-library/themes",
      manage: "/admin/legacy-library/manage",
      create: "/admin/legacy-library/new",
      single: "/admin/legacy-library/:id",
    },
    pathways: "/admin/pathways",
    pathway: "/admin/pathways/:id",
    pathwayNew: "/admin/pathways/new",
    reports: "/admin/reports",
    resources: "/admin/resources",
    resource: "/admin/resources/:id",
    resourceNew: "/admin/resources/new",
    seriesIndex: "/admin/series",
    series: "/admin/series/:id",
    seriesNew: "/admin/series/new",
    usage: "/admin/usage",
    user: "/admin/users/:id",
    users: "/admin/users"
  },
  construction: "/construction",
  categories: "/categories",
  categoryLanding: "/categories/landing/:slug",
  favorites: "/favorites",
  featured: "/featured",
  home: "/",
  oktaCallback: "/okta-callback",
  oktaLogin: "/okta-login",
  notes: "/profile/notes",
  // This URL comes from the network invitation email
  networkInviteLanding: "invitation/network-registration/:slug",
  // This URL comes from the network invitation email
  passwordResetLanding: "reset-password",
  pathway: "/pathway/:id",
  profile: "/profile/account",
  recentlyAdded: "/recently-added",
  recentlyViewed: "/recently-viewed",
  resource: "/resource/:id",
  series: "/series/:id",
  search: "/search",
  ssoCallback: "/sso-callback",
  legacySearch: "/legacy-library/search",
  legacyLibraryLandingWebpage: "/legacy-library",
  legacyLibraryAuthorWebPage: "/legacy-library/:id",
  legacyLibrarySeriesWebpage: "/legacy-library/:id/series/:seriesId",
  legacyLibrarySermonWebpage: "/legacy-library/:id/sermon/:resourceId",
  // This URL comes from the church invitation email
  teamInviteLanding: "invitation/team-registration/:slug",
  // This URL comes from the verify email
  verifyEmailLanding: "verify-email"
};
