import { DASHBOARD_UNIT_PADDING } from "../utils/constants";
import colors from "./globalColors";

export default {
  adminFloatingBox: {
    position: "sticky" as "sticky",
    top: 92,
    maxHeight: "95vh",
    overflow: "auto" as "auto"
  },
  button: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    display: "inline",
    margin: 0,
    padding: 0,
    outline: "none",
    "&:focus": {
      outline: 0,
      textDecoration: "none"
    },
    "&:hover": {
      textDecoration: "none"
    }
  },
  card: {
    display: "inline-block",
    verticalAlign: "top",
    marginRight: "1.5rem",
    marginBottom: "1.5rem"
  },
  dashboard: {
    card: {
      WebkitBoxShadow: "2px 2px 5px -2px rgba(138,138,138,1)",
      MozBoxShadow: "2px 2px 5px -2px rgba(138,138,138,1)",
      boxShadow: "2px 2px 5px -2px rgba(138,138,138,1)",
      borderRadius: 4,
      ".ant-card-body": {
        padding: 14
      }
    },
    cardMargin: {
      marginBottom: DASHBOARD_UNIT_PADDING
    },
    cardTitle: {
      fontWeight: "bold",
      display: "block",
    },
    error: {
      position: "absolute",
      bottom: 15,
      left: 0,
      right: 0,
      textAlign: "center",
      margin: "0 auto",
      width: "100%"
    },
    largeNumber: {
      fontSize: 45,
      lineHeight: "40px",
      fontWeight: "300"
    },
    largeNumberSubText: {
      fontSize: 12,
      color: "#848484"
    },
    listItem: {
      borderBottom: "0px !important",
      height: "26px",
      display: "flex",
      marginBottom: 5
    },
    listItemName: {
      flex: 2
    },
    listItemCount: {
      textAlign: "right",
      flex: 1,
      paddingRight: 5
    },
    listItemCountChart: {
      textAlign: "right",
      flex: 1,
      paddingRight: 5,
      fontSize: 22
    },
    smallNumber: {
      fontSize: 25,
      fontWeight: "300"
    },
    table: {
      ".ant-table": {
        border: "0 !important",
      },
      ".ant-table-body": {
        margin: "0 !important",
      },
      ".ant-table-placeholder": {
        padding: "0 !important"
      },
      ".ant-table-tbody>tr:hover>td": {
        cursor: "default !important",
      },
      ".ant-table-row>td": {
        whiteSpace: "pre-wrap !important",
        verticalAlign: "top"
      }
    },
    tableColumn: {
      border: "0 !important",
      padding: "2px !important"
    },
  },
  report: {
    table: {
      ".ant-table": {
        width: "100% !important",
        tableLayout: "fixed !important"
      },
      ".ant-table-body": {
        margin: "0 !important",
      },
      ".ant-table-placeholder": {
        padding: "0 !important"
      },
      ".ant-table-row>td": {
        whiteSpace: "pre-wrap !important",
        verticalAlign: "top"
      }
    },
    tableColumn: {
      border: "0 !important",
      padding: "2px !important"
    },
  },
  label: {
    fontSize: 13,
    fontWeight: 600,
    letterSpacing: "1.25px",
    // @ts-ignore
    textTransform: "uppercase" as TextTransformProperty
  }
};
