import React, { Component } from "react";
import Slider from "react-slick";
import { css, Placeholder } from "./";

type Props = {
  isMobile: boolean;
  items: FeaturedBanner[];
  loading: boolean;
};

export default class extends Component<Props> {
  renderCard = (item: FeaturedBanner, index: number) => {
    const { isMobile } = this.props;

    return (
      <a key={index} href={item.link}>
        <div
          {...css({
            ...styles.cardContainer,
            paddingTop: "27.5%",
            backgroundImage: `url(${item.imageUrl})`,
            height: isMobile ? 1 : 1,
            width: isMobile ? "100%" : "100%"
          })}
        >
          <div
            {...css({
              ...styles.cardInfoContainer,
              height: isMobile ? 30 : 59
            })}
          >
          </div>
        </div>
      </a>
    );
  };

  renderLoadingCard = () => (
    <Placeholder
      darkMode
      css={{
        paddingTop: "27.5%"
      }}
    />
  );

  render() {
    const { isMobile } = this.props;

    const settings = {
      arrows: true,
      autoplay: true,
      autoplaySpeed: 7000,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: "home-carousel"
    };
    return (
      <div {...css({ ...styles.container, marginTop: isMobile ? 50 : 0 })}>
        <div {...css(styles.innerContainer)}>
          <Slider {...settings}>
            {this.props.loading
              ? this.renderLoadingCard()
              : this.props.items.map((item, index) =>
                  this.renderCard(item, index)
                )}
          </Slider>
        </div>
      </div>
    );
  }
}

const styles = {
  cardContainer: {
    backgroundSize: "auto 100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
    display: "flex",
    alignItems: "flex-end"
  },
  cardInfoContainer: {
    width: "100%",
    paddingLeft: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  container: {
    textAlign: "center",
    margin: "0 20px"
  },
  image: {
    width: "100%"
  },
  innerContainer: {
    margin: "0 auto"
  }
};
