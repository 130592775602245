import React from "react";
import { css } from "./";
import colors from "../styles/globalColors";

export default (props: { css: Object; darkMode?: boolean }) => (
  <div
    {...css({
      ...props.css,
      backgroundColor: props.darkMode ? colors.darkestGray : colors.placeholder
    })}
  />
);
