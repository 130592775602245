import React, { Component } from "react";
import { Button } from "antd";
import { AuthContext } from "../context/AuthContext";
import { css } from "./";
import colors from "../styles/globalColors";

class ShadowUser extends Component {
  static contextType = AuthContext;

  onExitClick = () => {
    this.context.closeShadowUser();
  };

  render() {
    const { currentUser, isShadowing } = this.context;

    if (!isShadowing) {
      return null;
    }

    return (
      <div {...css(styles.container)}>
        You are impersonating {currentUser.firstName} {currentUser.lastName}
        <Button
          type="dashed"
          size="small"
          ghost
          {...css(styles.button)}
          onClick={this.onExitClick}
        >
          Exit
        </Button>
      </div>
    );
  }
}

const styles = {
  button: {
    marginLeft: 15
  },
  container: {
    backgroundColor: colors.green,
    height: 40,
    width: '100%',
    zIndex: 99,
    color: colors.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed"
  }
};

export default ShadowUser;
