import React, { Component } from "react";
import { Icon, Menu } from "antd";
import filter from "lodash/filter";
import flatMap from "lodash/flatMap";
import { Location } from "@reach/router";
import routes from "../routes/routes";
import { navigate } from "../utils/routerHistory";
import { AuthContext } from "../context/AuthContext";
import SubMenu from "antd/lib/menu/SubMenu";

export const ADMIN_MENU_ITEMS = [
  {
    icon: "dashboard",
    label: "Dashboard",
    routeName: routes.admin.dashboard
  },
  {
    icon: "user",
    label: "Users",
    routeName: routes.admin.users
  },
  {
    icon: "team",
    label: "Churches",
    routeName: routes.admin.churches
  },
  {
    icon: "folder",
    label: "Resources",
    routeName: routes.admin.resources
  },
  {
    icon: "ordered-list",
    label: "Series",
    routeName: routes.admin.seriesIndex
  },
  {
    icon: "profile",
    label: "Pathways",
    routeName: routes.admin.pathways
  },
  {
    icon: "tags",
    label: "Categories",
    routeName: routes.admin.categories
  },
  {
    icon: "notification",
    label: "Featured Banners",
    routeName: routes.admin.featuredBanners
  },
  {
    icon: "solution",
    label: "Authors",
    routeName: routes.admin.authors
  },
  {
    icon: "copy",
    label: "Copyrights",
    routeName: routes.admin.copyrights
  },
  {
    icon: "delete",
    label: "Data Cleanup",
    routeName: routes.admin.dataCleanupLogs
  },
  {
    icon: "read",
    label: "Legacy Library",
    routeName: routes.admin.legacyLibrary.series,
    subItems: [
      {
        label: "Series",
        routeName: routes.admin.legacyLibrary.seriesIndex
      },
      {
        label: "Themes",
        routeName: routes.admin.legacyLibrary.themes
      },
      {
        label: "Manage Libraries",
        routeName: routes.admin.legacyLibrary.manage
      }
    ]
  }
];

type Props = {
  className: string;
  router?: {
    pathname: string;
    push: Function;
  };
};

class AdminMenuItems extends Component<Props> {
  handleClick = (e: any) => {
    navigate(e.key);
  };

  renderMenuItem(icon, label, routeName, subItems = []) {
    if (subItems && subItems.length) {
      return (
        <SubMenu key={routeName} title={
          <span>
            <Icon type={icon} />
            {label}
          </span>
        }>
          {subItems.map(({ icon: subIcon, label: subLabel, routeName: subRouteName}) => (
            this.renderMenuItem(subIcon, subLabel, subRouteName)
          ))}
        </SubMenu>
      );
    }

    return (
      <Menu.Item key={routeName}>
        <Icon type={icon} />
        {label}
      </Menu.Item>
    );
  }

  render() {
    return (
      <AuthContext.Consumer>
        {({ checkPermission }) => {
          return (
            <Location>
              {({ location }) => {
                const selectedAdminKeys = filter(flatMap(ADMIN_MENU_ITEMS, (route) => {
                  return route.subItems || route;
                }), value =>
                  location.pathname.includes(value.routeName)
                );

                return (
                  <Menu
                    onClick={this.handleClick}
                    selectedKeys={selectedAdminKeys.map(item => item.routeName)}
                    mode="inline"
                    className={this.props.className}
                  >
                    {ADMIN_MENU_ITEMS.map(({ icon, label, routeName, subItems }) => (
                      this.renderMenuItem(icon, label, routeName, subItems)
                    ))}
                  </Menu>
                );
              }}
            </Location>
          );
        }}
      </AuthContext.Consumer>
    );
  }
}

export default AdminMenuItems;
