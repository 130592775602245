import API from "./baseService";

export const postLogin = (email: string, password: string) =>
  API.post("/authenticate", {
    email,
    password
  });

export const verifyEmail = (token: string) =>
  API.patch("/users/verify", {
    verify: token
  }).then(response => response.data);

export const resendVerifyEmail = () =>
  API.post("/users/verify").then(response => response.data);

export const postOktaAccessToken = (accessToken: string) =>
  API.post("/okta/auth", {
    accessToken
  });

export const postOpsId = (ops_id: string) =>
  API.post("/sso", {
    ops_id
  });

export const postSignUpAlt = (
  values: {
    firstName: string;
    lastName: string;
    email: string;
    organizationName: string;
    phone: string;
    postalCode: string;
    password: string;
    passwordConfirmation: string;
    marketingMediaId: string;
    churchRoleId: string;
  },
) =>
  API.post(
    "/registrations",
    {
      registration: {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        organizationName: values.organizationName,
        phone: values.phone,
        postal_code: values.postalCode,
        password: values.password,
        password_confirmation: values.passwordConfirmation,
        signup_library: location.hostname.indexOf("legacylibrar") > -1 ? "legacy" : "resource",
        marketing_media_id: values.marketingMediaId,
        church_role_id: values.churchRoleId
      }
    }
  );


export const postSignUp = (
  values: {
    firstName: string;
    lastName: string;
    email: string;
    organizationName: string;
    phone: string;
    postalCode: string;
    password: string;
    passwordConfirmation: string;
    signupLibrary: string;
    utmSource: string;
    utmMedium: string;
    utmCampaign: string;
    utmTerm: string;
    utmContent: string;
  },
  teamInviteSlug?: string
) =>
  API.post(
    "/registrations",
    {
      registration: {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        organizationName: values.organizationName,
        phone: values.phone,
        postal_code: values.postalCode,
        password: values.password,
        password_confirmation: values.passwordConfirmation,
        signup_library: values.signupLibrary,
        utm_source: values.utmSource,
        utm_medium: values.utmMedium,
        utm_campaign: values.utmCampaign,
        utm_term: values.utmTerm,
        utm_content: values.utmContent,
      }
    },
    {
      params: {
        invite_slug: teamInviteSlug
      }
    }
  );

export const postLogout = () => API.post("/sign_out");

export const sendResetPasswordEmail = (email: string) =>
  API.post("/password_resets", {
    reset: {
      email
    }
  });

export const resetPassword = (params: {
  token: string;
  password: string;
  passwordConfirmation: string;
}) =>
  API.patch("/password_resets", {
    reset: params
  });

export const changePassword = (params: {
  password: string;
  passwordConfirmation: string;
}) =>
  API.put("/password_changes", {
    password: params
  });
