import React, { Component } from "react";
import { PERMISSIONS, USER_ROLES, USER_TIERS } from "../utils/permissions";
import { getUser } from "../services/userService";
import routes from "../routes/routes";
import { navigate } from "../utils/routerHistory";
import { getMyTeam } from "../services/teamService";
import { clearStorage } from "../utils/localstorage";
import { isInLegacyLibrary } from "../utils/generalUtils";

type AuthProps = {
  checkPermission: (action: string) => boolean;
  setCurrentUserChurch: () => Promise<void>;
  isAdmin: () => boolean;
  isAuthenticated: () => boolean;
  isBackendUser: () => boolean;
  isPremium: () => boolean;
  isEditor: () => boolean;
  isPublicAuthUser: () => boolean;
  isPublicUser: () => boolean;
  isLegacyLibraryUser: () => boolean;
  isGuest: () => boolean;
  logout: (callback?: () => void) => void;
  profileIsComplete: () => boolean;
  profileCompletePercentage: () => number;
  setCurrentUser: (user: CurrentUser) => void;
  setCurrentUserAndAuth: (
    user: {
      user: CurrentUser;
      token: { auth: string; refresh: string };
    } | null
  ) => Promise<void>;
  setSsoUserAndAuth: (
    user: {
      user: CurrentUser;
      token: { auth: string; refresh: string };
    } | null
  ) => Promise<void>;
  closeShadowUser: () => void;
  isChurchNetwork: () => boolean;
  setShadowUser: (selectedUser: User, callback?: () => void) => Promise<void>;
  isStandard: () => boolean;
};

export const AuthContext = React.createContext({} as State & AuthProps);

export const AUTH_TOKEN_KEY = "authToken";
export const CURRENT_USER_KEY = "currentUser";
export const TEMP_CURRENT_USER_KEY = "tempCurrentUser";
export const REFRESH_TOKEN_KEY = "refreshToken";
export const IS_SHADOWING_KEY = "isShadowing";
export const SHADOW_USER_ID_KEY = "shadowUserId";

type Props = { children: any };

type State = {
  authToken: string | null;
  isShadowing: boolean;
  refreshToken: string;
  currentUser: CurrentUser | null;
  currentUserChurch: Church | null;
};

export default class Provider extends Component<Props, State> {
  async componentDidMount() {
    // This is created so that the logout method can be used in `baseService.js`
    // whenever there is a 401 response.
    (window as any).logoutCallback = this.logout;

    if (this.isAuthenticated()) {
      const user = await getUser(this.state.currentUser.id);
      this.setCurrentUser(user);
    }
  }

  setCurrentUserChurch = async () => {
    try {
      const currentUserChurch = await getMyTeam();

      this.setState({ currentUserChurch });
    } catch (e) {
      //
    }
  };

  setCurrentUser = (user: CurrentUser) => {
    localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(user));

    const isShadowing = JSON.parse(localStorage.getItem(IS_SHADOWING_KEY));

    this.setState({ currentUser: user, isShadowing: isShadowing || false });

    this.setCurrentUserChurch();
  };

  setCurrentUserAndAuth = (
    data: { user: CurrentUser; token: { auth: string; refresh: string } } | null
  ): Promise<void> => {
    if (data && data.user) {
      localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(data.user));
      localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(data.token.auth));
      localStorage.setItem(REFRESH_TOKEN_KEY, JSON.stringify(data.token.refresh));

      this.setState({
        authToken: data.token.auth,
        currentUser: data.user,
        refreshToken: data.token.refresh
      });

      this.setCurrentUserChurch();
    } else {
      clearStorage();

      this.setState({
        authToken: null,
        currentUser: null,
        currentUserChurch: null
      });
    }

    return Promise.resolve();
  };

  // Setting on opposite domain.
  setSsoUserAndAuth = (
    data: { user: CurrentUser; token: { auth: string; refresh: string } } | null
  ): Promise<void> => {
    if (data && data.user) {
      localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(data.user));
      localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(data.token.auth));
      localStorage.setItem(REFRESH_TOKEN_KEY, JSON.stringify(data.token.refresh));

      this.setState({
        authToken: data.token.auth,
        currentUser: data.user,
        refreshToken: data.token.refresh
      });

      this.setCurrentUserChurch();
    } else {
      clearStorage();

      this.setState({
        authToken: null,
        currentUser: null,
        currentUserChurch: null
      });
    }

    return Promise.resolve();
  };

  setShadowUser = async (selectedUser: User, callback?: () => void) => {
    try {
      const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER_KEY));

      const selectedUserInfo = await getUser(selectedUser.id);

      localStorage.setItem(SHADOW_USER_ID_KEY, JSON.stringify(selectedUserInfo.id));
      localStorage.setItem(TEMP_CURRENT_USER_KEY, JSON.stringify(currentUser));
      localStorage.setItem(IS_SHADOWING_KEY, JSON.stringify(true));

      if (callback) callback();

      this.setCurrentUser(selectedUserInfo);
    } catch (e) {
      //
    }
  };

  closeShadowUser = () => {
    const currentAdminUser = JSON.parse(localStorage.getItem(TEMP_CURRENT_USER_KEY));

    localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(currentAdminUser));
    localStorage.removeItem(TEMP_CURRENT_USER_KEY);
    localStorage.removeItem(SHADOW_USER_ID_KEY);
    localStorage.removeItem(IS_SHADOWING_KEY);

    this.setCurrentUser(currentAdminUser);

    navigate(routes.admin.users);
  };

  logout = (callback?: () => void) => {
    // Making sure that the current user & token get cleared out after
    // the logout request, regardless of it is successful or not.
    this.setCurrentUserAndAuth(null);

    // if (callback) callback();

    // NAVIGATE SSO - To Logout.
    if (isInLegacyLibrary()) {
      navigate(`${process.env.REACT_APP_DOMAIN}${routes.ssoCallback}`);
    } else {
      navigate(`${process.env.REACT_APP_LEGACY_LIBRARY_DOMAIN}${routes.ssoCallback}`);
    }
  };

  profileIsComplete = () => {
    const { currentUser, currentUserChurch } = this.state;
    const church = currentUserChurch && currentUserChurch.church;

    if (!currentUser || !church) return false;

    if (
      !currentUser.firstName ||
      !currentUser.lastName ||
      !currentUser.birthdate ||
      !currentUser.phone ||
      !currentUser.mobilePhone ||
      !currentUser.address1 ||
      !currentUser.city ||
      !currentUser.state ||
      !currentUser.postalCode ||
      !currentUser.country ||
      !church.address1 ||
      !church.city ||
      !church.state ||
      !church.postalCode ||
      !church.country ||
      !church.phone ||
      !church.website
    ) {
      return false;
    }
    return true;
  };

  profileCompletePercentage = () => {
    const { currentUser, currentUserChurch } = this.state;
    const user = currentUser as CurrentUser;
    const church = currentUserChurch && currentUserChurch.church;

    if (!user) return 0;

    let percent = 0;

    if (user.firstName) percent += 10;
    if (user.lastName) percent += 10;
    if (user.birthdate) percent += 10;
    if (user.phone) percent += 10;
    if (user.mobilePhone) percent += 10;
    if (user.address1 && user.city && user.state && user.postalCode && user.country) {
      percent += 20;
    }
    if (church && church.address1 && church.city && church.state && church.postalCode && church.country) {
      percent += 10;
    }
    if (church && church.phone) percent += 10;
    if (church && church.website) percent += 10;

    return percent;
  };

  isAuthenticated = () => {
    const { authToken, currentUser } = this.state;

    if (authToken && currentUser) return true;

    return false;
  };

  isAuthenticatedRole = (roles: Array<UserRole>) => {
    const { authToken, currentUser } = this.state;
    if (!authToken || !currentUser || !roles || !roles.length) return false;
    if (roles.includes[currentUser.role]) return true;
    return false;
  };

  checkPermission = (action: string): boolean => {
    const { currentUser } = this.state;
    if (!PERMISSIONS[action] || !currentUser) return false;
    if (PERMISSIONS[action].includes(currentUser.role)) return true;
    return false;
  };

  checkRole = (role: string) => {
    const { currentUser } = this.state;
    if (currentUser && currentUser.role === role) return true;
    return false;
  };

  checkTier = (tier: string) => {
    const { currentUser } = this.state;
    if (currentUser && currentUser.tier === tier) return true;
    return false;
  };

  isAdmin = () => this.checkRole(USER_ROLES.ADMIN);
  isChurchNetwork = () => this.checkRole(USER_ROLES.USER) && this.checkTier(USER_TIERS.NETWORK);

  isEditor = () => this.checkRole(USER_ROLES.EDITOR);
  isStandard = () => this.checkRole(USER_ROLES.USER) && this.checkTier(USER_TIERS.STANDARD);
  isPremium = () =>
    (this.checkRole(USER_ROLES.USER) || this.checkRole(USER_ROLES.ADMIN)) &&
    (this.checkTier(USER_TIERS.PREMIUM) || this.checkTier(USER_TIERS.NETWORK));

  isBackendUser = () => this.isAdmin() || this.isEditor();
  isPublicUser = () => this.isGuest() || this.isStandard() || this.isPremium() || this.isChurchNetwork();
  isPublicAuthUser = () => this.isStandard() || this.isPremium() || this.isChurchNetwork();

  isLegacyLibraryUser = () => this.isBackendUser() || this.isPublicAuthUser();

  isGuest = () => {
    const { currentUser } = this.state;
    if (!currentUser) return true;
    return false;
  };

  state: State & AuthProps = {
    authToken: JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY)) || null,
    checkPermission: this.checkPermission,
    closeShadowUser: this.closeShadowUser,
    currentUser: JSON.parse(localStorage.getItem(CURRENT_USER_KEY)) || null,
    currentUserChurch: null,
    setCurrentUserChurch: this.setCurrentUserChurch,
    isAuthenticated: this.isAuthenticated,
    isAdmin: this.isAdmin,
    isBackendUser: this.isBackendUser,
    isChurchNetwork: this.isChurchNetwork,
    isEditor: this.isEditor,
    isGuest: this.isGuest,
    isPremium: this.isPremium,
    isPublicAuthUser: this.isPublicAuthUser,
    isPublicUser: this.isPublicUser,
    isLegacyLibraryUser: this.isLegacyLibraryUser,
    isShadowing: JSON.parse(localStorage.getItem(IS_SHADOWING_KEY)) || false,
    isStandard: this.isStandard,
    logout: this.logout,
    profileIsComplete: this.profileIsComplete,
    profileCompletePercentage: this.profileCompletePercentage,
    refreshToken: JSON.parse(localStorage.getItem(REFRESH_TOKEN_KEY)) || null,
    setCurrentUser: this.setCurrentUser,
    setCurrentUserAndAuth: this.setCurrentUserAndAuth,
    setSsoUserAndAuth: this.setSsoUserAndAuth,
    setShadowUser: this.setShadowUser
  };

  render() {
    return <AuthContext.Provider value={this.state}>{this.props.children}</AuthContext.Provider>;
  }
}
