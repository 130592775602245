import React, { useContext } from 'react';
import { navigate } from '../utils/routerHistory';
import routes from '../routes/routes';
import css from './css';
import { Icon, Input } from 'antd';
import { GlobalContext } from '../context/GlobalStateContext';

const SearchModal = () => {

    const handleOnSearch = (e) => {
        // @ts-ignore
        navigate(routes.legacySearch, { state: { query: e.target.value || "" }}).then(() => window.location.reload())
    }

    const { isSearchModalVisible, isMobile } = useContext(GlobalContext)

    return (
      <div {...css({
        display: isSearchModalVisible ? "flex" : "none",
        opacity: 0.85, 
        backgroundColor: "#111", 
        position: "fixed", 
        top: 0, 
        bottom: 0,
        backdropFilter: "blur(15px)",
        left: 0, 
        right: 0,
        zIndex: 11,
        justifyContent: "center",
        alignItems: "center",
        transition: "all 0.25s"
      })}>
        <Input
          {...css({
            width: isMobile ? "80%" : "50%", 
            "& > input": {
              height: 80, 
              backgroundColor: "#111", 
              color: "#fff",
              fontSize: 40,
              border: "none",
              borderBottom: "1px solid #fff",
            },
            ".ant-input-prefix": {
              left: "-18px !important"
            },
            ".ant-input-clear-icon": {
              color: "#fff !important",
              fontSize: "40px !important"
            }
          })}
          prefix={
            <Icon 
              type="search" 
              style={{ 
                fontSize: 40, 
                color: '#fff'
              }} 
            />
          }
          allowClear
          placeholder="Search Test..."
          size="large"
          onPressEnter={handleOnSearch}
        />
      </div>
    )
}

export default SearchModal;