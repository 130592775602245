import React, { Component, Fragment } from "react";
import { Link } from "@reach/router";
import { Row, Col, Icon, Input } from "antd";
import { Interpolation } from "emotion";
import "../styles/wordpressStyles.css";
import { SettingsMenu, Search, LegacySearch, MobileSettingsMenu } from "./";
import { ADMIN_MENU_ITEMS } from "./AdminMenuItems";
import { GlobalContext } from "../context/GlobalStateContext";
import { AuthContext } from "../context/AuthContext";
import logo from "../assets/logo.png";
import logoWhite from "../assets/logoWhite.png";
import legacyLibraryLogo from "../assets/Logo-Legacy-Libraries-dark.png";
import legacyLibraryLogoWhite from "../assets/Logo-Legacy-Libraries-white.png";
import css from "./css";
import globalColors from "../styles/globalColors";
import routes from "../routes/routes";


type Props = {
  hideActions?: boolean;
  hideSearch?: boolean;
  isMobileMenu: boolean;
  useSidebarView: boolean;
  headerType: "public" | "admin" | "profile" | "legacyLibrary";
  darkMode?: boolean;
};

class MainMenu extends Component<Props> {
  menuItemStyle = () => {
    return {
      color: this.props.darkMode ? globalColors.white : globalColors.black,
      "&:hover": {
        color: this.props.darkMode ? globalColors.white : globalColors.black,
        borderBottom: `2px solid ${this.props.darkMode ? globalColors.white : globalColors.black}`
      }
    };
  };

  renderMenuItem(menuItem) {
    return (
      <li key={menuItem.id} className="menu-item">
        <a href={menuItem.url} {...css(this.menuItemStyle())}>
          {menuItem.name}
        </a>
      </li>
    );
  }

  renderDesktopMenu(toggleSettingsModal, toggleLoginModal, toggleSignUpModal, toggleMobileMenu) {
    const { darkMode, isMobileMenu, headerType, hideSearch } = this.props;
    if (isMobileMenu) return null;
    
    if (headerType === "admin") {
      return (
        <Fragment>
          <div className="container clearfix et_menu_container admin">
            <div id="et-top-navigation" className="admin">
              <nav id="top-menu-nav" className="admin">
                <div className="top-menu-right">
                  <ul id="top-menu" className="nav admin">
                    {this.renderActionsMenu(
                      toggleSettingsModal,
                      toggleLoginModal,
                      toggleSignUpModal,
                      toggleMobileMenu,
                      false
                    )}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Row type="flex" align="middle" className="container clearfix et_menu_container">
            
            <Col span={6}>
              <span {...css(styles.logoHelper)} />
              {this.props.headerType === "legacyLibrary" ? (
                <Link to={routes.legacyLibraryLandingWebpage}>
                  <img
                    {...css(styles.logo)}
                    src={darkMode ? legacyLibraryLogoWhite : legacyLibraryLogo}
                    alt="Gateway Legacy Library Logo"
                  />
                </Link>
              ) : (
                <Link to="/">
                  <img {...css(styles.logo)} src={darkMode ? logoWhite : logo} alt="Gateway Resource Library Logo" />
                </Link>
              )}
            </Col>
            <Col {...css(styles.grow)}>
              {darkMode && ! hideSearch && headerType !== "legacyLibrary" ? (
                <Search darkMode={darkMode} showInNavigation={darkMode} filters='filter_library_types:"resource"' />
              ) : darkMode && ! hideSearch && headerType === "legacyLibrary" ? (
                <LegacySearch darkMode={darkMode} showInNavigation={darkMode}  />
                
              ) : null}
            </Col>

            <Col offset={1}>
              <nav id="top-menu-nav">
                <ul id="top-menu" className="nav">
                  {this.renderActionsMenu(
                    toggleSettingsModal,
                    toggleLoginModal,
                    toggleSignUpModal,
                    toggleMobileMenu,
                    this.props.headerType === "legacyLibrary" ? true : false
                  )}
                </ul>
              </nav>
            </Col>
          </Row>
        </Fragment>
      );
    }
  }

  renderMobileMenu(toggleSettingsModal, toggleLoginModal, toggleSignUpModal, toggleMobileMenu, mobileMenuVisible) {
    const { darkMode, headerType, hideSearch } = this.props;

    let menuClassName = "mobile_menu_bar mobile_menu_bar_toggle";

    if (darkMode) {
      menuClassName += " dark-mode";
    }
    
    if (headerType === "admin") {
      const adminMenuItems = ADMIN_MENU_ITEMS;
  
        return (
          <Fragment>
            <Row className="container clearfix et_menu_container">
              <Col {...css(styles.logoContainer)}>
                <span {...css(styles.logoHelper)} />
                
                <Link to="/" {...css(styles.logoResourceAdmin)}>
                  <img {...css(styles.logo)} src={darkMode ? logoWhite : logo} alt="Gateway Resource Library Logo" />
                </Link>
              </Col>
              <Col>
                <div className="mobile_nav">
                  <span className="select_page">Select Page</span>
    
                  <span className={menuClassName} onClick={toggleMobileMenu} />
                    <ul id="mobile_menu" className={`et_mobile_menu ${mobileMenuVisible ? "open" : "closed"}`}>
                    {adminMenuItems.map((menuItem) =>
                      this.renderMenuItem({
                        id: menuItem.label,
                        name: menuItem.label,
                        url: menuItem.routeName
                      })
                    )}
                    <hr />
                    {this.renderActionsMenu(toggleSettingsModal, toggleLoginModal, toggleSignUpModal, toggleMobileMenu, false)}
                  </ul>
                </div>
              </Col>
            </Row>
          </Fragment>
         
        );
    } else if (headerType === "legacyLibrary") {
      return (
        <Fragment>
          <Row className="container clearfix et_menu_container">
            <Col {...css(styles.logoContainer)}>
              <span {...css(styles.logoHelper)} />
              
              <Link to={routes.legacyLibraryLandingWebpage}>
                <img
                  {...css(styles.logo)}
                  src={darkMode ? legacyLibraryLogoWhite : legacyLibraryLogo}
                  alt="Legacy Library Logo"
                />
              </Link>
            </Col>    
          </Row>
          { ! hideSearch ? (
            <Row>
              <Col className="container clearfix et_menu_container" span={24}>
                <LegacySearch darkMode={darkMode} showInNavigation />
              </Col>
            </Row>
          ) : null }
        
          
          <div className="mobile_action_menu">
            {this.renderMobileActionsMenu(toggleSettingsModal, toggleLoginModal, toggleSignUpModal, toggleMobileMenu, true)}
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Row className="container clearfix et_menu_container">
            <Col {...css(styles.logoContainer)}>
              <span {...css(styles.logoHelper)} />
              
              <Link to="/" {...css(styles.logoResourceAdmin)}>
                <img {...css(styles.logo)} src={darkMode ? logoWhite : logo} alt="Gateway Resource Library Logo" />
              </Link>
            </Col>
          </Row>
          { ! hideSearch ? (
            <Row>
              <Col {...css(styles.searchContainer)} span={24}>
                <Search darkMode={darkMode} showInNavigation />
              </Col>
            </Row>
          ) : null }
          <div className="mobile_action_menu">
            {this.renderMobileActionsMenu(toggleSettingsModal, toggleLoginModal, toggleSignUpModal, toggleMobileMenu, false)}
          </div>
        </Fragment>
      );
    }
  }

  renderActionsMenu(toggleSettingsModal, toggleLoginModal, toggleSignUpModal, toggleMobileMenu, isLegacyLibraryNav) {
    const { darkMode, hideActions } = this.props;
    if (hideActions) return null;

    return (
      <SettingsMenu
        darkMode={darkMode}
        toggleLoginModal={toggleLoginModal}
        toggleSignUpModal={toggleSignUpModal}
        toggleSettingsModal={toggleSettingsModal}
        toggleMobileMenu={toggleMobileMenu}
        isLegacyLibraryNav={isLegacyLibraryNav}
      />
    );
  }


  renderMobileActionsMenu(toggleSettingsModal, toggleLoginModal, toggleSignUpModal, toggleMobileMenu, isLegacyLibraryNav) {
    const { darkMode, hideActions } = this.props;
    if (hideActions) return null;

    return (
      <MobileSettingsMenu
        darkMode={darkMode}
        toggleLoginModal={toggleLoginModal}
        toggleSignUpModal={toggleSignUpModal}
        toggleSettingsModal={toggleSettingsModal}
        toggleMobileMenu={toggleMobileMenu}
        isLegacyLibraryNav={isLegacyLibraryNav}
      />
    );
  }

  renderMenu(toggleSettingsModal, toggleLoginModal, toggleSignUpModal, toggleMobileMenu, mobileMenuVisible) {
    const { isMobileMenu } = this.props;

    if (isMobileMenu) {
      return this.renderMobileMenu(toggleSettingsModal, toggleLoginModal, toggleSignUpModal, toggleMobileMenu, mobileMenuVisible);
    } else {
      return this.renderDesktopMenu(toggleSettingsModal, toggleLoginModal, toggleSignUpModal, toggleMobileMenu);
    }
  }

  render() {
    return (
      <GlobalContext.Consumer>
        {({ toggleLoginModal, toggleSignUpModal, toggleSettingsModal, toggleMobileMenu, mobileMenuVisible }) => (
          
          <AuthContext.Consumer>
            {() => (
              <Fragment>
                {this.renderMenu(
                  toggleSettingsModal,
                  toggleLoginModal,
                  toggleSignUpModal,
                  toggleMobileMenu,
                  mobileMenuVisible
                )}
              </Fragment>
            )}
          </AuthContext.Consumer>
        )}
      </GlobalContext.Consumer>
    );
  }
}

const styles: Record<
  "logoLegacy" | "logoContainer" | "logo" | "logoHelper" | "logoResourceAdmin" | "grow" | "searchContainer", 
  Interpolation
> = {
  logoLegacy: {
    backgroundColor: "#111",
    padding: 8,
    marginRight: 50
  },
  logoResourceAdmin: {
    marginRight: 20
  },
  logoContainer: {
    position: "absolute",
    height: "100%",
    pading: "10px",
    "@media all and (max-width: 980px)": {
      "&": {
        minHeight: 40
      }
    },
    "@media all and (max-width: 420px)": {
      "&": {
        height: 70
      }
    }
  },
  logo: {
    display: "inline-block",
    float: "none",
    marginBottom: 0,
    verticalAlign: "middle",
    maxHeight: 40,
    "& img": {
      height: '100%'
    },
    "@media all and (max-width: 980px)": {
      "&": {
        height: '100%'
      }
    }
  },
  logoHelper: {
    display: "inline-block",
    width: 0,
    height: "100%",
    verticalAlign: "middle"
  },
  grow: {
    flexGrow: 1
  },
  searchContainer: {
    paddingRight: "3%",
    paddingLeft: "3%"
  },
};

export default MainMenu;
