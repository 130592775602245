import React from "react";
import { Button, Icon } from "antd";
import css from "./css";
import globalColors from "../styles/globalColors";

export default (props: { isSelected: boolean; onEdit: any }) => {
  if (props.isSelected) {
    return (
      <span {...css({ color: globalColors.blue })}>
        Editing
        <Icon
          style={{
            paddingLeft: 6
          }}
          type="arrow-right"
        />
      </span>
    );
  }
  return (
    <Button size="small" type="primary" onClick={props.onEdit}>
      Edit
    </Button>
  );
};
