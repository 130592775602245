import groupBy from "lodash/groupBy";
import uniq from "lodash/uniq";
import { FilterParams } from "../pages/Search";
import { getAuthorName } from "./resourceUtils";
import get from "lodash/get";
import { uniqBy } from "lodash";
import { isInLegacyLibrary } from "./generalUtils";

export const groupSearchResults = (content: AlgoliaResponse) => {
  const groups = groupBy(content.hits, (item: AlgoliaResponseHit) => {
    return item.item_type_label;
  });

  return groups;
};

export const filterResultsByLibrary = (existingFilters: string | null) => {
  const libraryFilter = isInLegacyLibrary() ? "filter_library_types:legacy" : "filter_library_types:resource";
  const filters = existingFilters ? `${existingFilters} AND` : "";
  return `${filters} ${libraryFilter}`;
};

export const getFilterTags = (queryParams: FilterParams) => {
  const { attachmentTypes, authors, categories, tiers, tags, keywords } = queryParams;

  let filterTags: { type: string; name: string }[] = [];
  if (categories) {
    filterTags = [
      ...filterTags,
      ...(categories as string).split(",").map((category) => ({ type: "category", name: category }))
    ];
  }
  if (tags) {
    filterTags = [...filterTags, ...(tags as string).split(",").map((tag) => ({ type: "tag", name: tag }))];
  }
  if (keywords) {
    filterTags = [
      ...filterTags,
      ...(keywords as string).split(",").map((keyword) => ({ type: "keyword", name: keyword }))
    ];
  }
  if (attachmentTypes) {
    filterTags = [
      ...filterTags,
      ...(attachmentTypes as string).split(",").map((type) => ({ type: "attachmentType", name: type }))
    ];
  }
  if (authors) {
    filterTags = [...filterTags, ...(authors as string).split(",").map((author) => ({ type: "author", name: author }))];
  }
  if (tiers === "standard") {
    filterTags = [...filterTags, { type: "tier", name: tiers }];
  }
  return filterTags;
};

export const getSearchResultAuthors = (item: Resource | Series | Pathway): string[] => {
  let authors = [];
  if (item.type === "Resource") {
    authors = getResourceAuthors(item);
  } else if (item.type === "Series") {
    authors = [...authors, ...getSeriesAuthors(item)];
  } else if (item.type === "Pathway") {
    authors = [...authors, ...getPathwayAuthors(item)];
  }

  return uniq(authors);
};

export const getResourceAuthors = (resource: Resource) => resource.authors.map((author) => getAuthorName(author));

export const getSeriesAuthors = (series: Series) => {
  let authors = [];
  series.resources.forEach((resource) => {
    if (resource.authors && resource.authors.length > 0) {
      const resourceAuthors = resource.authors.map((author) => getAuthorName(author));
      authors = [...authors, ...resourceAuthors];
    }
  });
  return authors;
};

export const getPathwayAuthors = (pathway: Pathway) => {
  let authors = [];
  pathway.items.forEach((item) => {
    if (item.item && item.item.type === "Resource") {
      authors = [...authors, ...getResourceAuthors(item.item)];
    } else if (item.item && item.item.type === "Series") {
      authors = [...authors, ...getSeriesAuthors(item.item)];
    }
  });
  return authors;
};

export const getSearchResultAttachmentTypes = (item: Resource | Series | Pathway): string[] => {
  let attachmentTypes = [];
  if (item.type === "Resource") {
    attachmentTypes = getResourceAttachmentTypes(item);
  } else if (item.type === "Series") {
    attachmentTypes = [...attachmentTypes, ...getSeriesAttachmentTypes(item)];
  } else if (item.type === "Pathway") {
    attachmentTypes = [...attachmentTypes, ...getPathwayAttachmentTypes(item)];
  }

  return uniq(attachmentTypes);
};

export const getResourceAttachmentTypes = (resource: Resource) =>
  resource.attachments.map((attachment) => attachment.attachmentType);

export const getSeriesAttachmentTypes = (series: Series) => {
  let attachmentTypes = [];
  series.resources.forEach((resource) => {
    if (resource.attachments && resource.attachments.length > 0) {
      const resourceAttachmentTypes = resource.attachments.map((attachment) => attachment.attachmentType);
      attachmentTypes = [...attachmentTypes, ...resourceAttachmentTypes];
    }
  });
  return attachmentTypes;
};

export const getPathwayAttachmentTypes = (pathway: Pathway) => {
  let attachmentTypes = [];
  pathway.items.forEach((item) => {
    if (item.item && item.item.type === "Resource") {
      attachmentTypes = [...attachmentTypes, ...getResourceAttachmentTypes(item.item)];
    } else if (item.item && item.item.type === "Series") {
      attachmentTypes = [...attachmentTypes, ...getSeriesAttachmentTypes(item.item)];
    }
  });
  return attachmentTypes;
};

export const searchTitlesWithUniqueId = (resources, series) => {
  const alteredResources = resources.map((resource: Resource) => ({
    ...resource,
    uniqueId: `r_${resource.id}`,
    itemType: "Resource"
  }));

  const alteredSeries = series.map((resource: Resource) => ({
    ...resource,
    uniqueId: `s_${resource.id}`,
    itemType: "Series"
  }));

  return [...alteredResources, ...alteredSeries];
};

export const searchTitlesPathwayWithUniqueId = (
  resources: Resource[],
  series: Series[],
  pathway: Pathway[]
): SearchTitle[] => {
  const alteredResources = resources.map(
    (resource: Resource): SearchTitle => ({
      ...resource,
      uniqueId: `r_${resource.id}`,
      itemType: "Resource"
    })
  );

  const alteredSeries = series.map(
    (resource: Series): SearchTitle => ({
      ...resource,
      uniqueId: `s_${resource.id}`,
      itemType: "Series"
    })
  );

  const alteredPathways = pathway.map(
    (resource: Pathway): SearchTitle => ({
      ...resource,
      uniqueId: `p_${resource.id}`,
      itemType: "Pathway"
    })
  );

  return uniqBy([...alteredResources, ...alteredSeries, ...alteredPathways], (item) => item.uniqueId);
};

export const searchLegacyTitlesWithUniqueId = (resources, series) => {
  const alteredResources = resources.map((resource: Resource) => ({
    ...resource,
    uniqueId: `r_${resource.id}`,
    itemType: "Sermon"
  }));

  const alteredSeries = series.map((resource: Resource) => ({
    ...resource,
    uniqueId: `s_${resource.id}`,
    itemType: "Series"
  }));

  return [...alteredResources, ...alteredSeries];
};

export const extractLegacyItemsFromSearchTitles = (searchResults) => {
  const alteredResources = searchResults.map((item, index) => ({
    id: item.featureableId ? item.id : null,
    name: get(item, "details.name") || item.name,
    featureableId: item.featureableId || item.id,
    featureableType: item.featureableType
      ? item.featureableType
      : item.itemType === "Sermon" || item.itemType === "Resource"
      ? "Resource"
      : "Series",
    sort: index + 1,
    _destroy: item._destroy ? 1 : 0
  }));

  return [...alteredResources];
};

export const extractSearchTitlesFromLegacyItems = (resources) => {
  const searchTitles = resources.map((item, index) => ({
    ...item,
    id: item.id,
    name: get(item, "details.name") || item.name,
    featureableId: item.featureableId || null,
    featureableType: item.featureableType || null,
    sort: item.sort || index + 1
  }));

  return [...searchTitles];
};
