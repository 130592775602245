import React, { Component } from "react";
import { cx } from "emotion";
import { Layout } from "antd";
import "../styles/wordpressStyles.css";
import { GlobalContext } from "../context/GlobalStateContext";
import { AuthContext } from "../context/AuthContext";
import MainMenu from "./MainMenu";

const { Header } = Layout;

type Props = {
  hideActions?: boolean;
  hideSearch?: boolean;
  isMobileMenu: boolean;
  useSidebarView: boolean;
  headerType: "public" | "admin" | "profile" | "legacyLibrary";
  darkMode?: boolean;
};

class MainHeader extends Component<Props> {
  static contextType = AuthContext;

  headerClassName = () => {
    return cx(this.context.isShadowing ? "impersonating" : "not-impersonating", {
      "dark-mode": this.props.darkMode,
      "main-header-sidebar-view": this.props.useSidebarView
    });
  };

  render() {
    return (
      <GlobalContext.Consumer>
        {() => (
          <AuthContext.Consumer>
            {() => (
              <Header id="main-header" className={this.headerClassName()}>
                <MainMenu
                  hideActions={false}
                  hideSearch={true}
                  isMobileMenu={false}
                  useSidebarView={this.props.useSidebarView}
                  headerType={this.props.headerType}
                  darkMode={this.props.darkMode}
                />
              </Header>
            )}
          </AuthContext.Consumer>
        )}
      </GlobalContext.Consumer>
    );
  }
}

export default MainHeader;
