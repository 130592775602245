import React, { Component } from "react";
import { Icon, Tooltip } from "antd";
import { favoriteResource, favoriteSeries, favoritePathway, removeFavorite } from "../services/favoriteService";
import colors from "../styles/globalColors";

export enum LibraryType {
  Resource = 0,
  Legacy = 1
}

type Props = {
  item: Resource | Series | Pathway;
  reloadItem: () => Promise<void>;
  type: "Resource" | "Series" | "Pathway";
  libraryType?: LibraryType;
};

type State = {
  loading: boolean;
};

type FavoriteType = "resources" | "series" | "pathways";

const mapTypeToFavorite = {
  Resource: { type: "resources", handler: favoriteResource },
  Series: { type: "series", handler: favoriteSeries },
  Pathway: { type: "pathways", handler: favoritePathway }
};

class Favorite extends Component<Props, State> {
  state = {
    loading: false
  };

  onClick = async () => {
    const { item, type, libraryType = LibraryType.Resource } = this.props;
    const { favored } = item;
    try {
      this.setState({ loading: true });

      if (favored) {
        const favoriteType = mapTypeToFavorite[type].type as FavoriteType;
        await removeFavorite(item.id, favoriteType);
      } else {
        const request = mapTypeToFavorite[type].handler;
        await request(item.id, libraryType);
      }

      await this.props.reloadItem();

      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
      //
    }
  };

  render() {
    const { item, type } = this.props;
    const { loading } = this.state;

    if (!item) return null;

    const { favored } = item;

    const title = favored ? "Remove Favorite" : `Favorite this ${type}`;
    const theme = favored && !loading ? "filled" : "outlined";
    const icon = loading ? "loading" : "star";
    const color = favored ? colors.yellow : colors.white;

    return (
      <Tooltip title={title} placement="top">
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            this.onClick();
          }}
        >
          <Icon type={icon} theme={theme} style={{ color, fontSize: 20, marginTop: 5 }} />
        </a>
      </Tooltip>
    );
  }
}

export default Favorite;
