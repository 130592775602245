import React, { Component } from "react";
import { AutoComplete, Badge, Button, Input, Tooltip, Icon } from "antd";
import { SelectValue } from "antd/lib/select";
import map from "lodash/map";
import upperFirst from "lodash/upperFirst";
import { css } from "../";
import colors from "../../styles/globalColors";
import { isJSONString } from "../../utils/generalUtils";
import { isPremiumResource } from "../../utils/resourceUtils";

const Search = Input.Search;
const { Option, OptGroup } = AutoComplete;

export type OptionObject = {
  id?: number;
  name: string;
  item_type_label?: string;
};

type Props = {
  allowClear?: boolean;
  allowEmptySearch?: boolean;
  isMobile: boolean;
  dataSource: AlogliaSearchGroups;
  filterCount?: number;
  onFilterButtonPress?: () => void;
  onSearchUpdate: (value: OptionObject) => void;
  onSearchSelect: (value: OptionObject) => void;
  onSearchSubmit: (value: OptionObject) => void;
  searchInputValue?: string | null;
  placeholder?: string;
  style?: any;
  darkMode?: boolean;
};

type State = {
  autoCompleteOpen: boolean;
  searchInput: SelectValue;
  showInputHint: boolean;
};

export default class extends Component<Props, State> {
  state = {
    autoCompleteOpen: false,
    searchInput: this.props.searchInputValue || "",
    showInputHint: false
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.searchInputValue !== this.props.searchInputValue) {
      this.setState({ searchInput: this.props.searchInputValue });
    }
  }

  onSearch = (value: string) => {
    if (!this.props.allowEmptySearch && value === "") {
      this.setState({ showInputHint: true });
      setTimeout(() => this.setState({ showInputHint: false }), 3000);
    } else {
      this.setState({ autoCompleteOpen: false });
      const updatedValue = this.getJSON(value);
      this.props.onSearchSubmit(updatedValue);
    }
  };

  onSelect = (value: SelectValue, option: any) => {
    const updatedValue = this.getJSON(value);
    this.props.onSearchSelect(updatedValue);
  };

  onChange = (value: SelectValue, option: any) => {
    const updatedValue = this.getJSON(value);
    this.setState({ searchInput: value });
    this.props.onSearchUpdate(updatedValue);
  };

  getJSON = (value: SelectValue) =>
    (value as string).length > 0 && isJSONString(value as string)
      ? JSON.parse(value as string)
      : { name: value };

  renderTitle = (title: string) => <span>{upperFirst(title)}</span>;

  renderOptions = () =>
    map(this.props.dataSource, (group, key) => {
      return (
        <OptGroup key={key} label={this.renderTitle(key)}>
          {group.map(option => (
            <Option key={option.id.toString()} value={JSON.stringify(option)}>
              {option.name}{" "}
              {isPremiumResource(option) && (
                <Icon
                  type="lock"
                  style={{
                    color: "rgb(138, 138, 138)",
                    fontSize: 12,
                    marginLeft: 3
                  }}
                />
              )}
            </Option>
          ))}
        </OptGroup>
      );
    });

  render() {
    return (
      <div
        {...css({
          ...this.props.style
        })}
      >
        <div {...css(styles.container)}>
          <Tooltip
            title="Type search term here before searching"
            placement="top"
            visible={this.state.showInputHint}
          >
            <AutoComplete
              open={this.state.autoCompleteOpen}
              defaultActiveFirstOption={false}
              size="large"
              style={{ width: "100%" }}
              onBlur={() => this.setState({ autoCompleteOpen: false })}
              onFocus={() => this.setState({ autoCompleteOpen: true })}
              dataSource={this.renderOptions()}
              // @ts-ignore
              onChange={this.onChange}
              onSelect={this.onSelect}
              optionLabelProp="text"
              value={this.state.searchInput}
              className="global-search-autocomplete"
              {...css({
                "& > div": {
                  borderRadius: 15
                },
                ".ant-select-selection__rendered": {
                  height: 55
                }
              })}
            >
              <Search
                allowClear={this.props.allowClear}
                autoComplete="off"
                placeholder={this.props.placeholder || "Find a Resource..."}
                // enterButton
                size="large"
                name="searchInput"
                onSearch={this.onSearch}
                value={this.state.searchInput}
                className={`global-search-input ${
                  this.props.darkMode ? "dark-mode" : ""
                }`}
              />
            </AutoComplete>
          </Tooltip>
          {this.props.onFilterButtonPress && (
            <Badge
              count={this.props.filterCount || 0}
              style={{ backgroundColor: colors.green }}
            >
              <Button
                onClick={this.props.onFilterButtonPress}
                size="large"
                {...css(styles.filterButton)}
              >
                Filters
              </Button>
            </Badge>
          )}
        </div>
        {this.props.children}
      </div>
    );
  }
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
  },
  filterButton: {
    marginLeft: 15
  }
};
