import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import isBoolean from "lodash/isBoolean";
import { css } from "./";
import globalStyles from "../styles/globalStyles";
import colors from "../styles/globalColors";

export default (props: {
  errors: any;
  endDateRequired?: boolean;
  setFieldTouched: Function;
  setFieldValue: Function;
  touched: any;
  values: {
    featuredStart: moment.Moment | null;
    featuredEnd: moment.Moment | null;
  };
}) => {
  const {
    errors,
    endDateRequired,
    setFieldTouched,
    setFieldValue,
    touched,
    values
  } = props;

  const showEndDateNotRequired = isBoolean(endDateRequired)
    ? !endDateRequired
    : true;
  return (
    <div {...css(styles.inputContainer)}>
      <label {...css(globalStyles.label)}>
        Featured Dates {endDateRequired && "(required)"}
      </label>
      <div>
        <div style={styles.dateContainer}>
          <span style={{ display: "block" }}>Start</span>
          <DatePicker
            disabledDate={startValue => {
              const { featuredEnd } = values;
              if (!startValue || !featuredEnd) return false;
              return startValue.valueOf() > featuredEnd.valueOf();
            }}
            size="large"
            format="M-D-YYYY"
            value={values.featuredStart || undefined}
            placeholder="Start Date"
            onChange={date => {
              setFieldTouched("featuredStart");
              setFieldValue("featuredStart", date);
            }}
          />
        </div>
        <div style={styles.dateContainer}>
          <span style={{ display: "block" }}>
            End {showEndDateNotRequired && "(not required)"}
          </span>
          <DatePicker
            disabledDate={endValue => {
              const { featuredStart } = values;
              if (!endValue || !featuredStart) return false;
              return endValue.valueOf() <= featuredStart.valueOf();
            }}
            size="large"
            format="M-D-YYYY"
            value={values.featuredEnd || undefined}
            placeholder="End Date"
            onChange={date => {
              setFieldTouched("featuredEnd");
              setFieldValue("featuredEnd", date);
            }}
          />
        </div>
      </div>
      {touched.featuredStart && errors.featuredStart && (
        <p {...css({ color: colors.red })}>{errors.featuredStart}</p>
      )}
    </div>
  );
};

const styles = {
  dateContainer: {
    display: "inline-block",
    marginBottom: 10,
    marginRight: 10,
    marginTop: 10
  },
  inputContainer: {
    marginTop: 10,
    marginBottom: 20
  }
};
