import React from "react";
import { Interpolation } from "emotion";
import { Breadcrumb } from "antd";
import { Link } from "@reach/router";
import routes from "../routes/routes";
import css from "./css";
import colors from "../styles/globalColors";

const Breadcrumbs = (props: {
  location: { state: { fromSeries?: Series; fromPathway?: Pathway } };
  resource?: Resource;
  series?: Series;
  pathway?: Pathway;
}) => {
  const { location, resource, series, pathway } = props;
  if (location && location.state && (location.state.fromSeries || location.state.fromPathway)) {
    const { fromPathway, fromSeries } = location.state;

    const seriesRoute = fromSeries ? routes.series.replace(":id", fromSeries.id.toString()) : "/";
    const pathwayRoute = fromPathway ? routes.pathway.replace(":id", fromPathway.id.toString()) : "/";

    const current = resource || series || pathway;
    const currentType = resource ? "Resource" : series ? "Series" : pathway ? "Pathway" : null;

    return (
      <Breadcrumb {...css(styles.breadcrumbContainer)}>
        {fromPathway && (
          <Breadcrumb.Item>
            <Link to={pathwayRoute}>{fromPathway.name} (Pathway)</Link>
          </Breadcrumb.Item>
        )}
        {fromSeries && (
          <Breadcrumb.Item>
            {!fromPathway ? <Link to={seriesRoute}>{fromSeries.name} (Series)</Link> : <>{fromSeries.name} (Series)</>}
          </Breadcrumb.Item>
        )}
        {current && (
          <Breadcrumb.Item>
            {current.name} ({currentType})
          </Breadcrumb.Item>
        )}
      </Breadcrumb>
    );
  }
  return null;
};

const styles: Record<"breadcrumbContainer", Interpolation> = {
  breadcrumbContainer: {
    margin: "20px 25px 0px",
    "span, a": {
      color: colors.white
    },
    "a:hover": {
      color: colors.hoverWhite
    }
  }
};

export default Breadcrumbs;
