import React, { Component } from "react";
import { css, Interpolation } from "emotion";
import { Layout } from "antd";
import { AdminMenuItems } from "./";
import logo from "../assets/logo.png";
import legacyLibraryLogo from "../assets/Logo-Legacy-Libraries-dark.png";
import routes from "../routes/routes";

const { Sider } = Layout;

type Props = {
  isMobile: boolean;
  isInLegacyLibrary: boolean;
  type: "public" | "admin" | "profile";
};

const legacyUrl = `${process.env.REACT_APP_LEGACY_LIBRARY_DOMAIN || ""}${routes.legacyLibraryLandingWebpage}`;

class Sidebar extends Component<Props> {
  render() {
    return (
      <Sider
        theme="light"
        style={{ display: this.props.isMobile ? "none" : "block" }}
        className={css(styles.container)}
      >
        <div className={css(styles.logoContainer)}>
          <a href={process.env.REACT_APP_DOMAIN}>
            <img src={logo} alt="Gateway Resource Library Logo" className={css(styles.logo)} />
          </a>
          {this.props.isInLegacyLibrary && (
            <a href={legacyUrl}>
              <img
                style={{ background: "none", padding: "0 24px 0 0" }}
                src={legacyLibraryLogo}
                alt="Legacy Library Logo"
                className={css(styles.logo, styles.logoLegacy)}
              />
            </a>
          )}
        </div>
        <AdminMenuItems className={css({ borderRightColor: "transparent" })} />
      </Sider>
    );
  }
}

const styles: Record<string, Interpolation> = {
  container: {
    ".ant-layout-sider-children": {
      position: "fixed",
      overflow: "scroll"
    }
  },
  logoContainer: {
    textAlign: "center",
    paddingTop: 20,
    paddingBottom: 10,
    maxWidth: 200
  },
  logo: {
    height: "auto",
    marginRight: 16,
    marginLeft: 15
  },
  logoLegacy: {
    backgroundColor: "#111",
    marginTop: 20,
    padding: 8
  }
};

export default Sidebar;
