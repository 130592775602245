import "babel-polyfill";
import React, { lazy, Suspense, useEffect } from "react";
import { Router, Redirect, LocationProvider } from "@reach/router";
import { Container, ProtectedRoute } from "../components";
import routes from "./routes";
import { history } from "../utils/routerHistory";

const Construction = lazy(() => import("../pages/Construction"));
const Authors = lazy(() => import("../pages/admin/Authors"));
const Categories = lazy(() => import("../pages/admin/Categories"));
const Copyrights = lazy(() => import("../pages/admin/Copyrights"));
const Dashboard = lazy(() => import("../pages/admin/Dashboard"));
const DataCleanupLogs = lazy(() => import("../pages/admin/DataCleanupLogs"));
const FeaturedBanners = lazy(() => import("../pages/admin/FeaturedBanners"));
const LegacyLibrarySeries = lazy(() => import("../pages/admin/LegacyLibrary/Series"));
const LegacyLibrarySeriesSingle = lazy(() => import("../pages/admin/LegacyLibrary/Series/SingleSeries"));
const LegacyLibrarySeriesCreate = lazy(() => import("../pages/admin/LegacyLibrary/Series/CreateSeries"));
const LegacyLibraryThemes = lazy(() => import("../pages/admin/LegacyLibrary/Themes"));
const LegacyLibraryManage = lazy(() => import("../pages/admin/LegacyLibrary/Libraries"));
const LegacyLibraryCreate = lazy(() => import("../pages/admin/LegacyLibrary/Libraries/CreateLibrary"));
const LegacyLibrarySingle = lazy(() => import("../pages/admin/LegacyLibrary/Libraries/SingleLibrary"));
const OktaCallback = lazy(() => import("../pages/OktaCallback"));
const OktaRedirect = lazy(() => import("../pages/OktaRedirect"));
const Pathway = lazy(() => import("../pages/admin/Pathway"));
const Pathways = lazy(() => import("../pages/admin/Pathways"));
const Resource = lazy(() => import("../pages/admin/Resource"));
const Resources = lazy(() => import("../pages/admin/Resources"));
const Series = lazy(() => import("../pages/admin/Series"));
const SeriesIndex = lazy(() => import("../pages/admin/SeriesIndex"));
const Teams = lazy(() => import("../pages/admin/Teams"));
const Usage = lazy(() => import("../pages/admin/Usage"));
const User = lazy(() => import("../pages/admin/User"));
const Users = lazy(() => import("../pages/admin/Users"));

history.listen(() => {
  if (window.ga) {
    window.ga("send", "pageview");
  }
});

const ChildrensMinistryRedirect = () => {
  useEffect(() => {
    window.location.href = "https://gatewayresourcelibrary.com/sign-up-kids-resources";
  }, []);

  return null; // or a loading spinner, etc.
};

export default () => (
  <LocationProvider history={history}>
    <Container>
      <Suspense fallback={<div />}>
        <Router>
          <Construction path={routes.construction} />
          <ChildrensMinistryRedirect path="/categories/landing/children-s-ministry" />
          <OktaCallback path={routes.oktaCallback} />
          <OktaRedirect path={routes.oktaLogin} />
          <ProtectedRoute component={FeaturedBanners} path={routes.admin.featuredBanners} />
          <ProtectedRoute component={Dashboard} admin path={routes.admin.dashboard} />
          <ProtectedRoute component={Users} admin path={routes.admin.users} />
          <ProtectedRoute component={User} admin path={routes.admin.user} />
          <ProtectedRoute component={Teams} admin path={routes.admin.churches} />
          <ProtectedRoute component={Usage} admin path={routes.admin.usage} />
          <ProtectedRoute component={Pathways} admin path={routes.admin.pathways} />
          <ProtectedRoute component={Pathway} admin path={routes.admin.pathway} />
          <ProtectedRoute component={Resources} admin path={routes.admin.resources} />
          <ProtectedRoute component={Resource} admin path={routes.admin.resource} />
          <ProtectedRoute component={SeriesIndex} admin path={routes.admin.seriesIndex} />
          <ProtectedRoute component={Series} admin path={routes.admin.series} />
          <ProtectedRoute component={Categories} admin path={routes.admin.categories} />
          <ProtectedRoute component={Authors} admin path={routes.admin.authors} />
          <ProtectedRoute component={Copyrights} admin path={routes.admin.copyrights} />
          <ProtectedRoute component={DataCleanupLogs} admin path={routes.admin.dataCleanupLogs} />
          <ProtectedRoute component={LegacyLibrarySeries} admin path={routes.admin.legacyLibrary.seriesIndex} />
          <ProtectedRoute component={LegacyLibrarySeriesSingle} admin path={routes.admin.legacyLibrary.series} />
          <ProtectedRoute component={LegacyLibrarySeriesCreate} admin path={routes.admin.legacyLibrary.seriesNew} />
          <ProtectedRoute component={LegacyLibraryThemes} admin path={routes.admin.legacyLibrary.themes} />
          <ProtectedRoute component={LegacyLibraryManage} admin path={routes.admin.legacyLibrary.manage} />
          <ProtectedRoute component={LegacyLibraryCreate} admin path={routes.admin.legacyLibrary.create} />
          <ProtectedRoute component={LegacyLibrarySingle} admin path={routes.admin.legacyLibrary.single} />
          <Redirect from="*" to={routes.construction} default noThrow />
        </Router>
      </Suspense>
    </Container>
  </LocationProvider>
);
