import React from "react";
import { Card, List, Spin } from "antd";
import upperFirst from "lodash/upperFirst";
import { css } from "../";
import { VictoryContainer, VictoryPie } from "victory";
import { numberWithCommas } from "../../utils/generalUtils";
import globalStyles from "../../styles/globalStyles";
import colors from "../../styles/globalColors";

type Props = {
  cardStyle: any;
  chartData: Array<DashboardChartData>;
  error: boolean;
  loading: boolean;
  title: string;
  totalNumber: string;
};

export default (props: Props) => {
  const COLORS = [colors.yellow, colors.blue, colors.teal];

  const pieChartData = props.chartData
    ? props.chartData.map((item, index) => ({
        x: item.percent,
        y: item.percent,
        fill: COLORS[index]
      }))
    : [];

  return (
    <Spin spinning={props.loading}>
      <Card
        bordered={false}
        {...css([
          globalStyles.dashboard.card,
          globalStyles.dashboard.cardMargin,
          props.cardStyle
        ])}
      >
        <div {...css({ position: "absolute" })}>
          <span {...css(globalStyles.dashboard.cardTitle)}>{props.title}</span>
          <span {...css(globalStyles.dashboard.smallNumber)}>
            {props.totalNumber}
          </span>
        </div>
        {props.error ? (
          <div {...css(globalStyles.dashboard.error)}>Error loading data</div>
        ) : (
          <>
            <div {...css(styles.chartContainer)}>
              <VictoryContainer responsive={false} width={200} height={200}>
                <VictoryPie
                  standalone={false}
                  width={200}
                  height={200}
                  data={pieChartData}
                  innerRadius={100}
                  labelRadius={0}
                  style={{
                    data: {
                      fill: d => d.fill
                    },
                    labels: { fontSize: 20, fill: "white" }
                  }}
                />
              </VictoryContainer>
            </div>

            {props.chartData && props.chartData.length && (
              <List
                size="small"
                dataSource={props.chartData}
                renderItem={(item, index) => (
                  <List.Item {...css(globalStyles.dashboard.listItem)}>
                    <span
                      {...css([
                        globalStyles.dashboard.listItemName,
                        { flex: 1 }
                      ])}
                    >
                      {upperFirst(item.name)}
                    </span>
                    <span
                      {...css([
                        globalStyles.dashboard.listItemCountChart,
                        { color: COLORS[index] }
                      ])}
                    >
                      {numberWithCommas(item.count)}
                    </span>
                  </List.Item>
                )}
              />
            )}
          </>
        )}
      </Card>
    </Spin>
  );
};

const styles = {
  chartContainer: {
    marginTop: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
};
