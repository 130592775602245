import React from "react";
import { Tooltip } from "antd";
import upperFirst from "lodash/upperFirst";
import { css } from "./";
import colors from "../styles/globalColors";

type Props = {
  label: string;
  style?: Object;
};

const ResourceTierLabel = (props: Props) => (
  <Tooltip title={`${upperFirst(props.label)} Tier`}>
    <span {...css({ ...styles.resourceTier, ...props.style })}>
      {props.label}
    </span>
  </Tooltip>
);

const styles = {
  resourceTier: {
    color: colors.gray,
    paddingLeft: "5px",
    textTransform: "uppercase",
    fontSize: 10,
    fontWeight: "bold"
  }
};

export default ResourceTierLabel;
