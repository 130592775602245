import React, { Component, CSSProperties } from "react";
import { OptionObject } from "./home/SearchInput";
import routes from "../routes/routes";
import { navigate } from "../utils/routerHistory";
import { Button, Input, Icon } from "antd";

type Props = {
  allowClear?: boolean;
  darkMode?: boolean;
  showInNavigation?: boolean;
  filterCount?: number;
  filters?: string;
  placeholder?: string;
  searchIndex?: "published" | "system" | "editor";
  style?: CSSProperties;
  onSearchSelect?(value: OptionObject): void;
  onSearchSubmit?(value: OptionObject): void;
  onSearchClean?(): void;
};

type State = {
  autocompleteResults: AlogliaSearchGroups;
  searchInput: '';
};



class LegacySearch extends Component<Props, State> {
  handleOnSearch(e) {
    navigate(routes.legacySearch, { state: { query: ( document.getElementById('legacy-search-field') as HTMLInputElement ).value || "" } });
  }


  
  render() {
      return (
        <Input
          suffix={
            <Button
                onClick={this.handleOnSearch}
                size="large"
                style={{
                  marginLeft: 15,
                  background: "none",
                  border:"none",
                  padding: 0,
                }}
              >
              <Icon
                type="search"
                style={{
                  fontSize: '1.5em',
                  color: "#474849",
                  right: '10px'
                }}
              />
            </Button>
          }

          allowClear={false}
          placeholder="Search..."
          size="large"
          id='legacy-search-field'
          onPressEnter={this.handleOnSearch}
          className={`global-search-input ${
            this.props.darkMode ? "dark-mode" : ""
          }`}
        />
        
      );
  }
}

export default LegacySearch;
